import Login from "../center/pages/login/Login";
import MyCenterPage from "../center/pages/myCenter/MyCenterPage";
import UsersPage from "../center/pages/users/UsersPage";
import NewsListPage from "../center/pages/news/newsListPage/NewsListPage";
import NewsEditPage from "../center/pages/news/newsEditPage/NewsEditPage";
import ContactListPage from "../center/pages/contacts/contactListPage/ContactListPage";
import ContactAddPage from "../center/pages/contacts/contactAddPage/ContactAddPage";
import ContactEditPage from "../center/pages/contacts/contactEditPage/ContactEditPage";

export default class CenterRoutes {
  static public = [
    {
      key: "clinicLogin",
      path: "/login",
      exact: true,
      component: Login,
    },
  ];

  static private = [
    {
      key: "adminUsers",
      path: "/admin/users",
      exact: true,
      component: UsersPage,
      authorizedIf: (user) => user.isUsersAdmin,
    },
    // Editorial
    {
      key: "centerMyCenter",
      path: "/",
      exact: true,
      component: MyCenterPage,
    },
    
    // News
    {
      key: "centerListNews",
      path: "/news",
      exact: true,
      component: NewsListPage,
    },
    {
      key: "centerEditNews",
      path: "/news/:id",
      exact: true,
      component: NewsEditPage,
    },
    // Contacts
    {
      key: "centerListContacts",
      path: "/contacts",
      exact: true,
      component: ContactListPage,
    },
    {
      key: "centerAddContact",
      path: "/contacts/add",
      exact: true,
      component: ContactAddPage,
    },
    {
      key: "centerEditContact",
      path: "/contacts/:id",
      exact: true,
      component: ContactEditPage,
    },
  ];
}
