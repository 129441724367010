import React from "react";
import { PropTypes } from "prop-types";

import { Label, FormGroup } from "reactstrap";
import Input from "sharedComponents/input/Input";
import CreateButtonModal from "sharedComponents/buttons/createButtonModal/CreateButtonModal";

export default class CreateCategoryButton extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    modalVisible: false,
  };

  toggleModal = () =>
    this.setState((prevState) => {
      return { modalVisible: !prevState.modalVisible };
    });

  onSubmit = (formData) => {
    let data = { ...formData, type: this.state.type };

    if (this.state.type !== "video") {
      delete data.videoId;
    }

    return this.props.onSubmit(data);
  };

  render() {
    return (
      <CreateButtonModal
        text="Nouvelle catégorie"
        icon="plus-circle"
        onSubmit={this.onSubmit}
      >
        <FormGroup className="required">
          <Label>Titre</Label>
          <Input type="text" name="title" required />
        </FormGroup>
      </CreateButtonModal>
    );
  }
}
