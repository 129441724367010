import React from "react";
import { PropTypes } from "prop-types";
import { FormGroup, Label } from "reactstrap";
import Input from "sharedComponents/input/Input";
import validationErrors from "services/ValidationErrors";

export default class ItemForm extends React.PureComponent {
  static propTypes = {
    item: PropTypes.object,
  };

  render() {
    const { item } = this.props;
    return (
      <div className="faqItemForm">
        <FormGroup className="required">
          <Label htmlFor="question">Question</Label>
          <Input
            className="form-control"
            name="question"
            required
            maxLength={500}
            validations="maxLength:500"
            validationError={validationErrors.maxLength(500)}
            value={item?.question}
          />
        </FormGroup>

        <FormGroup className="required">
          <Label htmlFor="answer">Réponse</Label>
          <Input
            className="form-control"
            name="answer"
            type="textarea"
            required
            value={item?.answer}
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="externalLink">Lien externe</Label>
          <Input
            className="form-control"
            name="externalLink"
            maxLength={500}
            validations="maxLength:500"
            validationError={validationErrors.maxLength(500)}
            value={item?.externalLink}
          />
        </FormGroup>
      </div>
    );
  }
}
