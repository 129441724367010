import {
  Button,
  FormGroup,
  Label,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import React from "react";

import AuthBlock from "../../../sharedComponents/authBlock/AuthBlock";
import Authentication from "../../services/Authentication";
import ElsanLogo from "../../../sharedComponents/elsanLogo/ElsanLogo";
import Input from "../../../sharedComponents/input/Input";
import validationErrors from "../../../services/ValidationErrors";
import ValidationForm from "../../../sharedComponents/validationForm/ValidationForm";

import "./Login.scss";
import UserApi from "../../apis/user.api";
import StringHelper from "../../../helpers/StringHelper";
import ApiErrorsHelper from "../../../helpers/ApiErrorsHelper";

export default class Login extends React.PureComponent {
  state = {
    loginError: null,
    forgottenPasswordModalOpened: false,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({ title: "Connexion" });
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      forgottenPasswordModalOpened: !prevState.forgottenPasswordModalOpened,
    }));
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    if (this.state.loginError) {
      this.setState({ loginError: null });
    }

    let response = await UserApi.login(formData.email, formData.password).catch(
      (e) => {
        if (e.raw) {
          this.form.current.updateInputsWithError(
            ApiErrorsHelper.convertApiValidationError(e.raw)
          );
        }
        this.setState({ loginError: e });
        return null;
      }
    );

    if (response) {
      Authentication.login(response.token);
      this.props.history.push("/centre/");
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <>
        <AuthBlock
          title="ELSAN Rein"
          subtitle="Espace Centre"
          logo={<ElsanLogo />}
        >
          <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
            <div className="m-4">
              <FormGroup>
                <Label>Adresse email</Label>
                <Input
                  id="emailInput"
                  type="email"
                  name="email"
                  placeholder="Adresse email"
                  validations="isEmail"
                  validationError={validationErrors.isEMail}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>Mot de passe</Label>
                <Input
                  id="passwordInput"
                  type="password"
                  name="password"
                  placeholder="Mot de passe"
                  required
                />
                <div className="clearfix">
                  <Button
                    type="button"
                    color="link"
                    className="btn-forgotten-password p-0"
                    onClick={this.toggleModal}
                  >
                    Mot de passe oublié ?
                  </Button>
                </div>
              </FormGroup>

              {this.state.loginError &&
                !StringHelper.isNullOrEmpty(this.state.loginError.message) && (
                  <Alert color="danger">{this.state.loginError.message}</Alert>
                )}
            </div>
            <Button color="secondary" block className="loginButton">
              Valider
            </Button>
          </ValidationForm>
        </AuthBlock>

        <Modal
          isOpen={this.state.forgottenPasswordModalOpened}
          toggle={this.toggleModal}
        >
          <ModalHeader toggle={this.toggleModal}>
            Mot de passe oublié
          </ModalHeader>
          <ModalBody>
            Pour réinitialiser votre mot de passe, merci de contacter le RSI ou
            le référent ADEL de votre établissement.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" outline onClick={this.toggleModal} block>
              fermer
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
