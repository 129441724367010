import React from "react";
import _ from "lodash";

import { NotificationManager } from "react-notifications";
import ApiErrorsHelper from "helpers/ApiErrorsHelper";

import { Table, FormGroup, Badge } from "reactstrap";
import Block from "sharedComponents/block/Block";
import EditButtonLink from "sharedComponents/buttons/editButtonLink/EditButtonLink";
import DeleteButton from "sharedComponents/buttons/deleteButton/DeleteButton";
import PageDataLoader from "sharedComponents/pageDataLoader/PageDataLoader";
import CreateTipSheetButton from "./createTipSheetButton/CreateTipSheetButton";

import TipSheetsApi from "admin/apis/tipSheets.api";
import TipSheetCategories from "admin/apis/tipSheetCategories.api";
import FaqApi from "admin/apis/faq.api";

export default class TipSheetsListPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    tipSheets: null,
    categories: null,
    faqs: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Gestion des fiches conseil",
      activeMenuKey: "tips",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        clinics: null,
        categories: null,
        faqs: null,
      },
      async () => {
        let nextState = { isLoading: false };

        const data = await Promise.all([
          TipSheetsApi.getAll(),
          TipSheetCategories.getAll(),
          FaqApi.getAll(),
        ]).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (data) {
          nextState.tipSheets = _.sortBy(data[0], "title");
          nextState.categories = _.sortBy(data[1], "title");
          nextState.faqs = _.sortBy(data[2], "title");
        }

        this.setState(nextState);
      }
    );
  };

  onCreate = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let tipSheet = await TipSheetsApi.add(formData).catch((e) => {
      error = e;
      return null;
    });
    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    } else {
      this.props.history.push(`tipSheets/${tipSheet.id}`);
      return Promise.resolve();
    }
  };

  delete = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await TipSheetsApi.delete(id).catch((error) => {
      ApiErrorsHelper.manage(error);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success("Fiche conseil supprimée avec succès", null);
      this.loadData();
    }
  };

  getType = (tipSheet) => {
    switch (tipSheet.type) {
      case "video":
        return "Vidéo";
      case "text":
        return "Texte";
      case "external":
        return "Lien externe";
      default:
        return "";
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>fiches conseil</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des actualités"
        >
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.tipSheets) return null;

    return (
      <div className="ClinicsPage">
        <FormGroup className="text-right">
          <CreateTipSheetButton
            onSubmit={this.onCreate}
            categories={this.state.categories}
            faqs={this.state.faqs}
          />
        </FormGroup>

        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Titre</th>
              <th>Catégorie</th>
              <th>Type</th>
              <th className="width-auto"></th>
              <th className="width-auto"></th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.tipSheets.map((tipSheet) => {
              return (
                <tr key={tipSheet.id}>
                  <td>{tipSheet.title}</td>
                  <td>{tipSheet.category.title}</td>
                  <td>{this.getType(tipSheet)}</td>
                  <td className="text-center">
                    {tipSheet.isHighlighted && (
                      <Badge color="success">Mise en avant</Badge>
                    )}
                  </td>
                  <td className="text-center">
                    <Badge color={tipSheet.published ? "success" : "secondary"}>
                      {tipSheet.published ? "Publié" : "Non publié"}
                    </Badge>
                  </td>
                  <td className="width-auto text-center">
                    <EditButtonLink to={`/admin/tipSheets/${tipSheet.id}`} />
                    <DeleteButton
                      title={<span>Confirmation de suppression</span>}
                      data={tipSheet.id}
                      onConfirm={this.delete}
                      className="ml-1"
                      tooltip="Supprimer"
                    >
                      Voulez-vous réellement supprimer la fiche conseil{" "}
                      <i>{tipSheet.title}</i> ?
                    </DeleteButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
