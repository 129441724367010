import React from "react";

import { NotificationManager } from "react-notifications";
import ApiErrorsHelper from "../../../../helpers/ApiErrorsHelper";

import { FormGroup, Label, Button } from "reactstrap";
import { Link } from "react-router-dom";

import Block from "../../../../sharedComponents/block/Block";
import PageDataLoader from "../../../../sharedComponents/pageDataLoader/PageDataLoader";
import Input from "../../../../sharedComponents/input/Input";
import ValidationForm from "../../../../sharedComponents/validationForm/ValidationForm";
import validationErrors from "../../../../services/ValidationErrors";
import ImageUploader from "../../../../sharedComponents/imageUploader/ImageUploader";

import CenterContactsApi from "../../../apis/centerContacts.api";

export default class ContactListPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    contact: null,
  };

  constructor(props) {
    super(props);
    this.contactId = this.props.match.params.id;
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Gestion des contacts",
      activeMenuKey: "contacts",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      { isLoading: true, loadingError: false, contact: null },
      async () => {
        let nextState = { isLoading: false };
        nextState.contact = await CenterContactsApi.get(this.contactId).catch(
          (e) => {
            nextState.loadingError = true;
            return null;
          }
        );

        this.setState(nextState);
      }
    );
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;

    await CenterContactsApi.update(this.contactId, formData).catch((e) => {
      ApiErrorsHelper.manage(e, this.form);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success("Contact modifié avec succès", null);
      this.props.history.push("/centre/contacts");
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Modifier un <strong>contact</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des contacts"
        >
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.contact) return null;
    return (
      <div className="ContactAddPage">
        <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Nom</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="lastName"
                maxLength={255}
                validationError={validationErrors.maxLength(255)}
                value={this.state.contact.lastName}
                required
              />
            </div>
          </FormGroup>
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Prénom</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="firstName"
                maxLength={255}
                validationError={validationErrors.maxLength(255)}
                value={this.state.contact.firstName}
                required
              />
            </div>
          </FormGroup>
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Rôle</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="role"
                maxLength={500}
                validationError={validationErrors.maxLength(500)}
                value={this.state.contact.role}
                required
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">
              Numéro de télephone
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="phoneNumber"
                maxLength={10}
                validationError={validationErrors.maxLength(10)}
                value={this.state.contact.phoneNumber}
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">Email</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="email"
                maxLength={500}
                validationError={validationErrors.isEMail}
                value={this.state.contact.email}
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">Photo</Label>
            <div className="col-sm-10">
              <ImageUploader
                name="filePicture"
                id="filePicture"
                helpText="Taille attendue : 1125px par 788px"
                actualUrl={this.state.contact.photoUrl}
              />
            </div>
          </FormGroup>
          <FormGroup className="text-right">
            <Link
              to="/centre/contacts"
              className="btn btn-outline-primary mr-1"
              type="button"
            >
              Retour à la liste
            </Link>
            <Button type="submit" color="primary">
              Valider
            </Button>
          </FormGroup>
        </ValidationForm>
      </div>
    );
  };
}
