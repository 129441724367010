import { Label, FormGroup, Button } from "reactstrap";
import { PropTypes } from "prop-types";
import CreateButtonModal from "sharedComponents/buttons/createButtonModal/CreateButtonModal";
import ImageUploader from "sharedComponents/imageUploader/ImageUploader";
import Input from "sharedComponents/input/Input";
import React from "react";
import Select from "sharedComponents/select/Select";
import YoutubeInput from "sharedComponents/youtubeInput/YoutubeInput";

export default class CreateTipSheetButton extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    categories: PropTypes.array.isRequired,
    faqs: PropTypes.array.isRequired,
  };

  state = {
    modalVisible: false,
    type: "text",
  };

  toggleModal = () =>
    this.setState((prevState) => {
      return { modalVisible: !prevState.modalVisible };
    });

  onSubmit = (formData) => {
    let data = { ...formData, type: this.state.type };

    if (this.state.type !== "video") {
      delete data.videoId;
    }

    return this.props.onSubmit(data);
  };

  selectTextArticle = () => this.setState({ type: "text" });
  selectVideoArticle = () => this.setState({ type: "video" });
  selectExternalArticle = () => this.setState({ type: "external" });
  selectFaq = () => this.setState({ type: "faq" });

  render() {
    const { type } = this.state;
    const { faqs, categories } = this.props;

    const catOptions = categories.map((c) => {
      return {
        value: c.id,
        label: c.title,
      };
    });

    const faqOptions =
      type !== "faq" || !faqs
        ? []
        : faqs.map((c) => {
            return {
              value: c.id,
              label: c.title,
            };
          });

    return (
      <CreateButtonModal
        text="Nouvelle fiche conseil"
        icon="plus-circle"
        onSubmit={this.onSubmit}
      >
        <FormGroup className="required">
          <Label>Titre</Label>
          <Input type="text" name="title" required />
        </FormGroup>

        <FormGroup className="required">
          <Label>Catégorie</Label>
          <Select
            id="categoryId"
            name="categoryId"
            placeholder="Sélectionner la catégorie"
            required
            options={catOptions}
          />
        </FormGroup>

        <FormGroup>
          <Label>Type</Label>
          <div className="d-flex flex-row justify-content-around">
            <Button
              size="sm"
              block
              className="mx-1"
              color={type === "text" ? "primary" : "light"}
              onClick={this.selectTextArticle}
            >
              Texte
            </Button>
            <Button
              size="sm"
              block
              className="mx-1"
              color={type === "video" ? "primary" : "light"}
              onClick={this.selectVideoArticle}
            >
              Vidéo
            </Button>
            <Button
              size="sm"
              block
              className="mx-1"
              color={type === "external" ? "primary" : "light"}
              onClick={this.selectExternalArticle}
            >
              Externe
            </Button>
            <Button
              size="sm"
              block
              className="mx-1"
              color={type === "faq" ? "primary" : "light"}
              onClick={this.selectFaq}
            >
              FAQ
            </Button>
          </div>
        </FormGroup>

        <FormGroup className="required">
          <Label>Petite image (aperçu sur la liste des articles)</Label>
          <ImageUploader
            name="smallPictureFile"
            helpText="Taille attendue : 417px par 417px"
            required
          />
        </FormGroup>

        <FormGroup className="required">
          <Label>Grande image (entête de l'article)</Label>
          <ImageUploader
            name="largePictureFile"
            helpText="Taille attendue : 1125px par 788px"
            required
          />
        </FormGroup>

        <div className={type === "video" ? "" : "d-none"}>
          <FormGroup className="required">
            <Label>ID YouTube de la vidéo</Label>
            <YoutubeInput name="videoId" required={type === "video"} />
          </FormGroup>
        </div>

        <div className={type === "external" ? "" : "d-none"}>
          <FormGroup className="required">
            <Label>Lien</Label>
            <Input
              type="text"
              name="externalUrl"
              required={type === "external"}
            />
          </FormGroup>
        </div>

        <div className={type === "faq" ? "" : "d-none"}>
          <FormGroup className="required">
            <Label htmlFor="faqId">FAQ</Label>
            <Select
              id="faqId"
              name="faqId"
              placeholder="Choisir une FAQ"
              required={type === "faq"}
              options={faqOptions}
            />
          </FormGroup>
        </div>
      </CreateButtonModal>
    );
  }
}
