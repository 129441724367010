import React from "react";

import ApiClient from "./services/ApiClient";
import Authentication from "./services/Authentication";

import { Redirect } from "react-router-dom";
import PrivateLayout from "../layouts/privateLayout/PrivateLayout";

export default class AdminPrivateLayout extends React.PureComponent {
  editorialMenuItems = [
    {
      menuKey: "centers",
      label: "Établissements",
      icon: "hospital-alt",
      path: "/centers",
    },
    {
      menuKey: "news",
      label: "Actualités",
      icon: "newspaper",
      path: "/news",
    },
    {
      menuKey: "tipSheetCategories",
      label: "Catégories fiches conseil",
      icon: "newspaper",
      path: "/tipSheetCategories",
    },
    {
      menuKey: "tips",
      label: "Fiches conseil",
      icon: "newspaper",
      path: "/tipSheets",
    },
    {
      menuKey: "faq",
      label: "FAQ",
      icon: "question-circle",
      path: "/faq",
    },
  ];

  adminMenuItems = [
    {
      menuKey: "admins",
      label: "Administrateurs",
      icon: "users",
      path: "/admins",
    },
  ];

  render() {
    if (!Authentication.isLogged()) {
      return <Redirect to={`/admin/login`} />;
    }

    let sideMenuItems = [];

    // EDITORIAL
    sideMenuItems.push({
      menuKey: "editorial",
      label: "Éditorial",
      subItems: this.editorialMenuItems,
    });

    // Super Admin
    if (Authentication.user.isSuperAdmin) {
      sideMenuItems.push({
        menuKey: "administration",
        label: "Administration",
        subItems: this.adminMenuItems,
      });
    }

    return (
      <PrivateLayout
        {...this.props}
        sideMenuTitle="ELSAN Rein"
        sideMenuSubitle="Back Office admin"
        routesPrefix="admin"
        unauthorizedRoutePath="login"
        authentication={Authentication}
        apiClient={ApiClient}
        sideMenuItems={sideMenuItems}
      />
    );
  }
}
