import AddCenterPage from "admin/pages/centers/addCenterPage/AddCenterPage";
import AdminsPage from "admin/pages/adminsPage/AdminsPage";
import CentersPage from "admin/pages/centers/centersPage/CentersPage";
import EditCenterPage from "admin/pages/centers/editCenterPage/EditCenterPage";
import EditFaqPage from "admin/pages/faq/editFaqPage/EditFaqPage";
import FaqListPage from "admin/pages/faq/faqListPage/FaqListPage";
import Login from "admin/pages/login/Login";
import NewsEditPage from "admin/pages/news/newsEditPage/NewsEditPage";
import NewsListPage from "admin/pages/news/newsListPage/NewsListPage";
import TipSheetCategoriesPage from "admin/pages/tipSheetCategoriesPage/TipSheetCategoriesPage";
import TipSheetEditPage from "admin/pages/tipSheets/tipSheetEditPage/TipSheetEditPage";
import TipSheetsListPage from "admin/pages/tipSheets/tipSheetsListPage/TipSheetsListPage";

export default class AdminRoutes {
  static public = [
    {
      key: "adminLogin",
      path: "/login",
      exact: true,
      component: Login,
    },
  ];

  static private = [
    {
      key: "adminHome",
      path: "/",
      exact: true,
      component: CentersPage,
    },
    {
      key: "adminCenters",
      path: "/centers",
      exact: true,
      component: CentersPage,
    },
    {
      key: "adminAddCenter",
      path: "/centers/add",
      exact: true,
      component: AddCenterPage,
    },
    {
      key: "adminEditCenter",
      path: "/centers/:id",
      exact: true,
      component: EditCenterPage,
    },
    {
      key: "adminAdmins",
      path: "/admins",
      exact: true,
      component: AdminsPage,
    },

    // News
    {
      key: "adminListNews",
      path: "/news",
      exact: true,
      component: NewsListPage,
    },
    {
      key: "admiEditNews",
      path: "/news/:id",
      exact: true,
      component: NewsEditPage,
    },

    // TipSheets
    {
      key: "adminListTipSheetCategories",
      path: "/tipSheetCategories",
      exact: true,
      component: TipSheetCategoriesPage,
    },
    {
      key: "adminListTipSheets",
      path: "/tipSheets",
      exact: true,
      component: TipSheetsListPage,
    },
    {
      key: "admiEditTipSheet",
      path: "/tipSheets/:id",
      exact: true,
      component: TipSheetEditPage,
    },

    // FAQ
    {
      key: "faqList",
      path: "/faq",
      exact: true,
      component: FaqListPage,
    },
    {
      key: "editFaqList",
      path: "/faq/:id",
      exact: true,
      component: EditFaqPage,
    },
  ];
}
