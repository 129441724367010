import { PropTypes } from "prop-types";
import React from "react";

import { Label, FormGroup } from "reactstrap";
import ImageUploader from "../../../../../sharedComponents/imageUploader/ImageUploader";

export default class ImageBlockEditor extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object,
    storagePath: PropTypes.string
  };

  render() {
    return (
      <FormGroup>
        <Label>URL</Label>
        <ImageUploader
          name="file"
          actualUrl={
            this.props.data
              ? `${this.props.storagePath}${this.props.data.url}`
              : null
          }
          required
        />
      </FormGroup>
    );
  }
}
