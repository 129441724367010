import { PropTypes } from "prop-types";
import React from "react";
import Input from "../../../../../sharedComponents/input/Input";
import { Label, FormGroup } from "reactstrap";

export default class SubtitleBlockEditor extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object
  };

  render() {
    return (
      <FormGroup>
        <Label>Sous-titre</Label>
        <Input
          name="text"
          value={this.props.data ? this.props.data.text : ""}
          required
        />
      </FormGroup>
    );
  }
}
