import React from "react";
import PropTypes from "prop-types";

import { NotificationManager } from "react-notifications";
import { withLayoutContextProvider } from "services/LayoutContext";
import validationErrors from "services/ValidationErrors";
import ApiErrorsHelper from "helpers/ApiErrorsHelper";

import { Button, Label, FormGroup } from "reactstrap";
import Checkbox from "sharedComponents/checkbox/Checkbox";
import Input from "sharedComponents/input/Input";
import ValidationForm from "sharedComponents/validationForm/ValidationForm";
import ImageUploader from "sharedComponents/imageUploader/ImageUploader";
import YoutubeInput from "sharedComponents/youtubeInput/YoutubeInput";

import CenterApi from "center/apis/center.api";

class GeneralTab extends React.PureComponent {
  static propTypes = {
    center: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await CenterApi.update(formData).catch((e) => {
      ApiErrorsHelper.manage(e, this.form);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success("Modifications enregistrées avec succès", "");
    }
  };

  render = () => {
    const center = this.props.center;
    return (
      <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Nom</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="name"
              required
              maxLength={255}
              validations="maxLength:255"
              validationError={validationErrors.maxLength(255)}
              value={center.name}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">
            Destinataire et/ou service
          </Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="addressLine2"
              maxLength={500}
              validations="maxLength:500"
              validationError={validationErrors.maxLength(500)}
              value={center.addressLine2}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Complément</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="addressLine3"
              helpText="Entrée, Tour, Immeuble, Bâtiment, Résidence, Zone industrielle, ..."
              maxLength={500}
              validations="maxLength:500"
              validationError={validationErrors.maxLength(500)}
              value={center.addressLine3}
            />
          </div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">N° et voie</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="addressLine4"
              required
              maxLength={500}
              validations="maxLength:500"
              validationError={validationErrors.maxLength(500)}
              value={center.addressLine4}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Mentions spéciales</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="addressLine5"
              helpText="BP, Tri Service Arrivée, ..."
              maxLength={500}
              validations="maxLength:500"
              validationError={validationErrors.maxLength(500)}
              value={center.addressLine5}
            />
          </div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">
            Code postal et ville
          </Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="addressLine6"
              required
              maxLength={500}
              validations="maxLength:500"
              validationError={validationErrors.maxLength(500)}
              value={center.addressLine6}
            />
          </div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Pays</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="addressLine7"
              required
              maxLength={500}
              validations="maxLength:500"
              validationError={validationErrors.maxLength(500)}
              value={center.addressLine7}
            />
          </div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Latitude</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="latitude"
              required
              validations="isNumeric"
              validationError={validationErrors.isNumeric}
              value={center.latitude}
            />
          </div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Longitude</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="longitude"
              required
              validations="isNumeric"
              validationError={validationErrors.isNumeric}
              value={center.longitude}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Site web</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="website"
              validations="isUrl"
              validationError={validationErrors.isUrl}
              value={center.website}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Numéro de téléphone</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="phoneNumber"
              maxLength={10}
              validations="maxLength:10"
              validationError={validationErrors.maxLength(10)}
              value={center.phoneNumber}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Email</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="email"
              maxLength={500}
              validations="maxLength:500"
              validationError={validationErrors.maxLength(500)}
              value={center.email}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Logo</Label>
          <div className="col-sm-10">
            <ImageUploader name="logoFile" actualUrl={center.logoUrl} />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">
            ID YouTube de la vidéo
          </Label>
          <div className="col-sm-10">
            <YoutubeInput
              name="presentationVideoId"
              value={center.presentationVideoId}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-sm-2">Caractéristiques</Label>
          <div className="col-sm-10">
            <Checkbox
              id="isUAD"
              name="isUAD"
              label="UAD"
              checked={center.isUAD}
            />
            <Checkbox
              id="isUDM"
              name="isUDM"
              label="UDM"
              checked={center.isUDM}
            />
            <Checkbox
              id="isLourd"
              name="isLourd"
              label="Lourd"
              checked={center.isLourd}
            />
          </div>
        </FormGroup>

        <FormGroup className="text-right">
          <Button type="submit" color="primary">
            Valider
          </Button>
        </FormGroup>
      </ValidationForm>
    );
  };
}

export default withLayoutContextProvider(GeneralTab);
