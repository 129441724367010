import ApiClient from "../services/ApiClient";
import queryString from "query-string";

export default class TipSheetsApi {
  static get = (id, withStructuredContent = false) => {
    let params = {};

    if (withStructuredContent) {
      params.withStructuredContent = true;
    }

    return  ApiClient.get(`tipSheets/${id}?${queryString.stringify(params)}`);
  };
 
  static getAll = () => ApiClient.get("tipSheets");
  static add = (data) => ApiClient.post("tipSheets", data, true);
  static delete = (id) => ApiClient.delete(`tipSheets/${id}`);
  static editGeneral = (id, data) => ApiClient.post(`tipSheets/${id}/general`, data, true);

  static addBlock = (id, type, data) =>
    ApiClient.post(`tipSheets/${id}/blocks/${type}`, data, true);

  static editBlock = (tipSheetId, blockId, data) =>
    ApiClient.post(
      `tipSheets/${tipSheetId}/blocks/${blockId}/content`,
      data,
      true
    );

  static moveBlock = (tipSheetId, blockId, data) =>
    ApiClient.patch(`tipSheets/${tipSheetId}/blocks/${blockId}/order`, data);

  static deleteBlock = (tipSheetId, blockId) =>
    ApiClient.delete(`tipSheets/${tipSheetId}/blocks/${blockId}`);


}
