import { FormGroup, Table } from "reactstrap";
import { NotificationManager } from "react-notifications";
import React from "react";

import Block from "../../../sharedComponents/block/Block";
import InlineLoader from "../../../sharedComponents/inlineLoader/InlineLoader";
import LoadingError from "../../../sharedComponents/loadingError/LoadingError";
import DeleteButton from "../../../sharedComponents/buttons/deleteButton/DeleteButton";
import CreateButtonModal from "../../../sharedComponents/buttons/createButtonModal/CreateButtonModal";
import EditButtonModal from "../../../sharedComponents/buttons/editButtonModal/EditButtonModal";
import Authentication from "../../services/Authentication";
import UserForm from "./userForm/UserForm";

import UsersApi from "../../apis/users.api";

export default class UsersPage extends React.PureComponent {
  static propTypes = {};

  state = {
    isLoading: true,
    loadingError: null,
    users: [],
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Gestion des utilisateurs",
      activeMenuKey: "adminUsers",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState({ isLoading: true, loadingError: null }, async () => {
      let nextState = { isLoading: false };
      let data = await UsersApi.getAll().catch((e) => {
        nextState.loadingError = e;
        return null;
      });

      if (data) {
        nextState.users = data;
      }

      this.setState(nextState);
    });
  };

  deleteUser = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await UsersApi.delete(id).catch((error) => {
      NotificationManager.error(error.message, "Une erreur est survenue");
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success("Utilisateur supprimé avec succès", null);
      this.loadData();
    }
  };

  saveAddModal = async (formData) => {
    if (
      !formData.isEditorialAdmin &&
      !formData.isQoDAdmin &&
      !formData.isUsersAdmin
    ) {
      return Promise.reject({
        message: "Veuillez attribuer au moins un droit à l'utilisateur",
      });
    } else {
      this.props.layoutContextProvider.showLoader();

      let error = null;

      await UsersApi.create(formData).catch((e) => {
        error = e;
      });

      this.props.layoutContextProvider.hideLoader();

      if (!error) {
        NotificationManager.success("L'utilisateur a été créé", null);
        this.loadData();
        return Promise.resolve();
      } else {
        return Promise.reject(error);
      }
    }
  };

  saveEditModal = async (userId, formData) => {
    if (
      !formData.isEditorialAdmin &&
      !formData.isQoDAdmin &&
      !formData.isUsersAdmin
    ) {
      return Promise.reject({
        message: "Veuillez attribuer au moins un droit à l'utilisateur",
      });
    } else {
      this.props.layoutContextProvider.showLoader();

      let error = null;
      await UsersApi.update(userId, formData).catch((e) => {
        error = e;
      });

      this.props.layoutContextProvider.hideLoader();

      if (!error) {
        NotificationManager.success("Modification effectuée", null);
        this.loadData();
        return Promise.resolve();
      } else {
        return Promise.reject(error);
      }
    }
  };

  renderTable = () => {
    return (
      <div>
        <FormGroup className="text-right">
          <CreateButtonModal
            text="Nouvel utilisateur"
            onSubmit={this.saveAddModal}
          >
            <UserForm passwordRequired />
          </CreateButtonModal>
        </FormGroup>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Email</th>
              <th>Droits</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.users.map((user) => {
              return (
                <tr key={user.id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>
                    <i>
                      {user.isEditorialAdmin && <div>Contenu éditorial</div>}
                      {user.isQoDAdmin && <div>QoD</div>}
                      {user.isUsersAdmin && <div>Gestion des utilisateurs</div>}
                      {user.isRoomReservationsAdmin && (
                        <div>Gestion de l'hôtellerie</div>
                      )}
                    </i>
                  </td>
                  <td className="width-auto text-center">
                    <EditButtonModal
                      title="Modifier un utilisateur"
                      data={user.id}
                      onSubmit={this.saveEditModal}
                      className="mr-1"
                    >
                      <UserForm user={user} />
                    </EditButtonModal>
                    {Authentication.user.id !== user.id && (
                      <DeleteButton
                        title={
                          <span>
                            Supprimer <i>{user.name}</i>
                          </span>
                        }
                        data={user.id}
                        onConfirm={this.deleteUser}
                        className="ml-1"
                      >
                        Voulez-vous réellement supprimer <i>{user.name}</i> ?
                      </DeleteButton>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>utilisateurs</strong>
          </span>
        }
      >
        {this.state.isLoading && (
          <InlineLoader block xl text="Chargement des utilisateurs" />
        )}

        {!this.state.isLoading && this.state.loadingError && (
          <LoadingError
            error={this.state.loadingError}
            onRetry={this.loadData}
          />
        )}

        {!this.state.isLoading && this.renderTable()}
      </Block>
    );
  }
}
