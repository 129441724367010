import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import OrderingHelper from "helpers/OrderingHelper";
import React from "react";
import CreateButtonModal from "sharedComponents/buttons/createButtonModal/CreateButtonModal";
import ItemForm from "./itemForm/ItemForm";
import EditButtonModal from "sharedComponents/buttons/editButtonModal/EditButtonModal";
import DeleteButton from "sharedComponents/buttons/deleteButton/DeleteButton";

export default class ItemsTab extends React.PureComponent {
  static props = {
    faq: PropTypes.object.isRequired,
    onChangeorder: PropTypes.func,
  };

  onDrop = async (result) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    let reordered = OrderingHelper.reorder(
      this.props.faq.items,
      result.source.index,
      result.destination.index
    );

    for (var i = 0; i < reordered.length; i++) {
      reordered[i].order = i + 1;
    }

    this.props.onChangeOrder(
      {
        from: result.source.index,
        to: result.destination.index,
      },
      reordered
    );
  };

  render() {
    const { faq } = this.props;
    return (
      <div className="faqItems">
        <div className="d-flex justify-content-end">
          <CreateButtonModal
            text="Ajouter une question"
            onSubmit={this.props.onCreate}
          >
            <ItemForm />
          </CreateButtonModal>
        </div>

        {faq && faq.items && (
          <DragDropContext onDragEnd={this.onDrop}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className={`droppable ${
                    snapshot.isDraggingOver ? "dragging" : "notDragging"
                  }`}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {_.sortBy(faq.items, "order").map((item, i) => (
                    <Draggable
                      key={`faqQuestion-${item.order}`}
                      draggableId={`${item.order}`}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div
                            className={`draggableRow d-flex flex-row justify-content-between align-items-start`}
                          >
                            <div className="d-flex flex-row align-items-center">
                              <FontAwesomeIcon icon="arrows-alt" />
                              <div className="mx-2">
                                <strong>{item.question}</strong>
                              </div>
                              <div className="small">
                                <i>{item.answer}</i>
                              </div>
                            </div>
                            <div>
                              <EditButtonModal
                                data={item.id}
                                onSubmit={this.props.onEdit}
                                tooltip="Modifier la question"
                              >
                                <ItemForm item={item} />
                              </EditButtonModal>
                              <DeleteButton
                                title={<span>Confirmation de suppression</span>}
                                data={item.id}
                                onConfirm={this.props.onDelete}
                                className="ml-1"
                                tooltip="Supprimer"
                              >
                                Voulez-vous réellement supprimer cette question
                                ?
                              </DeleteButton>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
    );
  }
}
