import React from "react";

import classnames from "classnames";
import _ from "lodash";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import PageDataLoader from "sharedComponents/pageDataLoader/PageDataLoader";
import Block from "sharedComponents/block/Block";
import GeneralTab from "./generalTab/GeneralTab";
import ContentTab from "./contentTab/ContentTab";

import TipSheetsApi from "admin/apis/tipSheets.api";
import TipSheetCategories from "admin/apis/tipSheetCategories.api";
import FaqApi from "admin/apis/faq.api";

export default class TipSheetEditPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    tipSheet: null,
    categories: null,
    faqs: null,
    activeTab: "general",
  };

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.props.layoutContextProvider.change({
      title: "Gestion des fiches conseil",
      activeMenuKey: "tips",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        tipSheet: null,
        categories: null,
      },
      async () => {
        let nextState = { isLoading: false };

        const data = await Promise.all([
          TipSheetsApi.get(this.id, true),
          TipSheetCategories.getAll(),
          FaqApi.getAll(),
        ]).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (data) {
          nextState.tipSheet = data[0];
          nextState.categories = _.sortBy(data[1], "title");
          nextState.faqs = _.sortBy(data[2], "title");
        }

        this.setState(nextState);
      }
    );
  };

  goToGeneralTab = () => {
    this.setState({ activeTab: "general" });
    this.props.history.replace("#general");
  };

  goToContentTab = () => {
    this.setState({ activeTab: "content" });
    this.props.history.replace("#content");
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Modifier une <strong>fiche conseil</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement de la fiche conseil"
        >
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (this.state.tipSheet === null) {
      return null;
    }

    return (
      <>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "general",
              })}
              onClick={this.goToGeneralTab}
            >
              Général
            </NavLink>
          </NavItem>
          {this.state.tipSheet.type === "text" && (
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "content",
                })}
                onClick={this.goToContentTab}
              >
                Contenu
              </NavLink>
            </NavItem>
          )}
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="general">
            <GeneralTab
              tipSheet={this.state.tipSheet}
              categories={this.state.categories}
              faqs={this.state.faqs}
            />
          </TabPane>
          {this.state.tipSheet.type === "text" && (
            <TabPane tabId="content">
              <ContentTab tipSheet={this.state.tipSheet} />
            </TabPane>
          )}
        </TabContent>
      </>
    );
  };
}
