import ApiClient from "../services/ApiClient";
import queryString from "query-string";

export default class NewsApi {
  static get = (id, withStructuredContent = false) => {
    let params = {};

    if (withStructuredContent) {
      params.withStructuredContent = true;
    }

    return ApiClient.get(`news/${id}?${queryString.stringify(params)}`);
  };

  static getAll = () => ApiClient.get("news");

  static add = (data) => ApiClient.post("news", data, true);
  static delete = (id) => ApiClient.delete(`news/${id}`);
  static editGeneral = (id, data) =>
    ApiClient.post(`news/${id}/general`, data, true);

  static addBlock = (id, type, data) =>
    ApiClient.post(`news/${id}/blocks/${type}`, data, true);

  static editBlock = (newsId, blockId, data) =>
    ApiClient.post(`news/${newsId}/blocks/${blockId}/content`, data, true);

  static moveBlock = (newsId, blockId, data) =>
    ApiClient.patch(`news/${newsId}/blocks/${blockId}/order`, data);

  static deleteBlock = (newsId, blockId) =>
    ApiClient.delete(`news/${newsId}/blocks/${blockId}`);
}
