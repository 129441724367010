import React from "react";

import classnames from "classnames";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import PageDataLoader from "../../../../sharedComponents/pageDataLoader/PageDataLoader";
import Block from "../../../../sharedComponents/block/Block";
import GeneralTab from "./generalTab/GeneralTab";
import ContentTab from "./contentTab/ContentTab";

import NewsApi from "../../../apis/news.api";

export default class NewsListPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    news: null,
    activeTab: "general",
  };

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.props.layoutContextProvider.change({
      title: "Gestion des actualités",
      activeMenuKey: "news",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      { isLoading: true, loadingError: false, news: null },
      async () => {
        let nextState = { isLoading: false };

        nextState.news = await NewsApi.get(this.id, true).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        this.setState(nextState);
      }
    );
  };

  goToGeneralTab = () => {
    this.setState({ activeTab: "general" });
    this.props.history.replace("#general");
  };

  goToContentTab = () => {
    this.setState({ activeTab: "content" });
    this.props.history.replace("#content");
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Modifier une <strong>actualité</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement de l'actualité"
        >
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (this.state.news === null) {
      return null;
    }

    return (
      <>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "general",
              })}
              onClick={this.goToGeneralTab}
            >
              Général
            </NavLink>
          </NavItem>
          {this.state.news.type === "text" && (
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "content",
                })}
                onClick={this.goToContentTab}
              >
                Contenu
              </NavLink>
            </NavItem>
          )}
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="general">
            <GeneralTab news={this.state.news} />
          </TabPane>
          {this.state.news.type === "text" && (
            <TabPane tabId="content">
              <ContentTab news={this.state.news} />
            </TabPane>
          )}
        </TabContent>
      </>
    );
  };
}
