import React from "react";
import { PropTypes } from "prop-types";

import { Label, FormGroup } from "reactstrap";
import ImageUploader from "sharedComponents/imageUploader/ImageUploader";
import CreateButtonModal from "sharedComponents/buttons/createButtonModal/CreateButtonModal";

export default class AddPhotoButton extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  onSubmit = async (formData) => {
    return this.props.onSubmit(formData);
  };

  render() {
    return (
      <CreateButtonModal
        text="Nouvelle photo"
        icon="plus-circle"
        onSubmit={this.props.onSubmit}
      >
        <FormGroup className="required">
          <Label>Photo</Label>
          <ImageUploader
            name="pictureFile"
            helpText="Taille attendue : 1125px par 633px"
            required
          />
        </FormGroup>
      </CreateButtonModal>
    );
  }
}
