import { PropTypes } from "prop-types";
import React from "react";

import ElsanLogo from "../../../sharedComponents/elsanLogo/ElsanLogo";
import SideMenuItem, { SideMenuItemProps } from "./sideMenuItem/SideMenuItem";

import "./SideMenu.scss";

export const SideMenuProps = PropTypes.arrayOf(
  PropTypes.shape({
    ...SideMenuItemProps,
    subItems: PropTypes.arrayOf(SideMenuItemProps),
  })
);

export default class SideMenu extends React.PureComponent {
  static props = {
    items: SideMenuProps,
    routesPrefix: PropTypes.string.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  };

  render() {
    return (
      <div className="sideMenuContent">
        <div className="sideMenuTitle px-2 pt-2">
          <ElsanLogo />
          <span className="ml-2 sideMenuText">{this.props.title}</span>
        </div>
        <div className="sideMenuSubtitle mb-4 px-2">{this.props.subtitle}</div>

        <ul className="list-unstyled pt-2">
          {this.props.items.map((i) => (
            <SideMenuItem
              {...i}
              key={i.menuKey}
              routePrefix={this.props.routesPrefix}
            />
          ))}
        </ul>
      </div>
    );
  }
}
