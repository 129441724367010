import React from "react";
import { PropTypes } from "prop-types";
import "./SideMenuItem.scss";
import { withLayoutContextProvider } from "../../../../services/LayoutContext";
import StringHelper from "../../../../helpers/StringHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

export const SideMenuItemProps = {
  menuKey: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconLib: PropTypes.string,
  label: PropTypes.string.isRequired,
  path: PropTypes.string
};

class SideMenuItem extends React.PureComponent {
  static propTypes = {
    ...SideMenuItemProps,
    routePrefix: PropTypes.string.isRequired
  };

  state = {
    opened: true,
    changed: false
  };

  toggle = () => {
    this.setState(prevState => {
      return {
        opened: prevState.changed
          ? !prevState.opened
          : !(
              prevState.opened ||
              this.props.layoutContextProvider.activeMenuKey.includes(
                this.props.menuKey
              )
            ),
        changed: true
      };
    });
  };

  render() {
    return (
      <>
        <li
          className={`menuItem ${
            !this.props.subItems &&
            this.props.layoutContextProvider.activeMenuKey ===
              this.props.menuKey
              ? "active"
              : ""
          } ${this.state.opened ? "opened" : ""} p-0`}
        >
          {this.props.subItems
            ? this.renderAsParentItem(this.state.opened)
            : this.renderAsLink()}
        </li>
        {this.state.opened &&
          this.props.subItems &&
          this.props.subItems.length > 0 &&
          this.props.subItems.map(i => {
            const Comp = withLayoutContextProvider(SideMenuItem);
            return (
              <Comp
                {...i}
                key={i.menuKey}
                routePrefix={this.props.routePrefix}
              />
            );
          })}
      </>
    );
  }

  renderAsLink() {
    return (
      <Link to={`/${this.props.routePrefix}${this.props.path}`}>
        {!StringHelper.isNullOrEmpty(this.props.icon) && (
          <span className="mx-2">
            <FontAwesomeIcon
              icon={
                this.props.iconLib
                  ? [this.props.iconLib, this.props.icon]
                  : this.props.icon
              }
            />
          </span>
        )}
        {this.props.label}
      </Link>
    );
  }

  renderAsParentItem(opened) {
    return (
      <>
        <Button
          color="link"
          onClick={this.toggle}
          block
          className="menuItemTitle mr-2"
        >
          <div className="menuItemTitleContent">
            {!StringHelper.isNullOrEmpty(this.props.icon) && (
              <span className="mx-2">
                <FontAwesomeIcon
                  icon={
                    this.props.iconLib
                      ? [this.props.iconLib, this.props.icon]
                      : this.props.icon
                  }
                />
              </span>
            )}
            {this.props.label}
            {this.state.changed && !this.state.opened && (
              <span className="ml-2 small">
                <FontAwesomeIcon
                  icon={`${opened ? "chevron-up" : "chevron-down"}`}
                />
              </span>
            )}
          </div>
        </Button>
      </>
    );
  }
}

export default withLayoutContextProvider(SideMenuItem);
