import React from "react";

import { NotificationManager } from "react-notifications";
import ApiErrorsHelper from "helpers/ApiErrorsHelper";
import validationErrors from "services/ValidationErrors";

import { Link } from "react-router-dom";
import { Button, FormGroup, Label } from "reactstrap";
import ImageUploader from "sharedComponents/imageUploader/ImageUploader";
import Checkbox from "sharedComponents/checkbox/Checkbox";
import Block from "sharedComponents/block/Block";
import Input from "sharedComponents/input/Input";
import ValidationForm from "sharedComponents/validationForm/ValidationForm";
import YoutubeInput from "sharedComponents/youtubeInput/YoutubeInput";

import CentersApi from "admin/apis/centers.api";

export default class AddCenterPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Gestion des centres",
      activeMenuKey: "centers",
    });
  }

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let createdCenter = await CentersApi.add(formData).catch((error) => {
      ApiErrorsHelper.manage(error, this.form);
    });

    this.props.layoutContextProvider.hideLoader();

    if (createdCenter) {
      NotificationManager.success("Centre créé avec succès", null);
      this.props.history.push(`/admin/centers/${createdCenter.id}`);
    }
  };

  render() {
    return (
      <Block
        title={
          <span>
            Création d'un <strong>centre</strong>
          </span>
        }
      >
        <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Nom</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="name"
                required
                maxLength={255}
                validations="maxLength:255"
                validationError={validationErrors.maxLength(255)}
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">
              Destinataire et/ou service
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="addressLine2"
                maxLength={500}
                validations="maxLength:500"
                validationError={validationErrors.maxLength(500)}
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">Complément</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="addressLine3"
                helpText="Entrée, Tour, Immeuble, Bâtiment, Résidence, Zone industrielle, ..."
                maxLength={500}
                validations="maxLength:500"
                validationError={validationErrors.maxLength(500)}
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">N° et voie</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="addressLine4"
                required
                maxLength={500}
                validations="maxLength:500"
                validationError={validationErrors.maxLength(500)}
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">
              Mentions spéciales
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="addressLine5"
                helpText="BP, Tri Service Arrivée, ..."
                maxLength={500}
                validations="maxLength:500"
                validationError={validationErrors.maxLength(500)}
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">
              Code postal et ville
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="addressLine6"
                required
                maxLength={500}
                validations="maxLength:500"
                validationError={validationErrors.maxLength(500)}
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Pays</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="addressLine7"
                required
                maxLength={500}
                validations="maxLength:500"
                validationError={validationErrors.maxLength(500)}
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Latitude</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="latitude"
                required
                validations="isNumeric"
                validationError={validationErrors.isNumeric}
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Longitude</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="longitude"
                required
                validations="isNumeric"
                validationError={validationErrors.isNumeric}
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">Site web</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="website"
                validations="isUrl"
                validationError={validationErrors.isUrl}
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">
              Numéro de téléphone
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="phoneNumber"
                maxLength={10}
                validations="maxLength:10"
                validationError={validationErrors.maxLength(10)}
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">Email</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="email"
                maxLength={500}
                validations="maxLength:500"
                validationError={validationErrors.maxLength(500)}
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Logo</Label>
            <div className="col-sm-10">
              <ImageUploader required name="logoFile" />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">
              ID YouTube de la vidéo
            </Label>
            <div className="col-sm-10">
              <YoutubeInput name="presentationVideoId" />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-sm-2">Caractéristiques</Label>
            <div className="col-sm-10">
              <Checkbox id="isUAD" name="isUAD" label="UAD" />
              <Checkbox id="isUDM" name="isUDM" label="UDM" />
              <Checkbox id="isLourd" name="isLourd" label="Lourd" />
            </div>
          </FormGroup>

          <FormGroup className="text-right">
            <Link
              to="/admin/centers"
              className="btn btn-outline-primary mr-1"
              type="button"
            >
              Retour à la liste
            </Link>
            <Button type="submit" color="primary">
              Valider
            </Button>
          </FormGroup>
        </ValidationForm>
      </Block>
    );
  }
}
