import ApiClient from "../services/ApiClient";

export default class CentersApi {
  static getAll = () => ApiClient.get("centers");

  static add = (data) => ApiClient.post("centers", data, true);

  static edit = (centerId, data) =>
    ApiClient.post(`centers/${centerId}`, data, true);

  static get(centerId) {
    return ApiClient.get(`centers/${centerId}`);
  }

  static getUsers(centerId) {
    return ApiClient.get(`centers/${centerId}/users`);
  }

  static addUser(centerId, data) {
    return ApiClient.post(`centers/${centerId}/users`, data);
  }

  static editUser(centerId, userId, data) {
    return ApiClient.patch(`centers/${centerId}/users/${userId}`, data);
  }

  static deleteUser(centerId, userId) {
    return ApiClient.delete(`centers/${centerId}/users/${userId}`);
  }
}
