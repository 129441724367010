import React from "react";
import PropTypes from "prop-types";

import { NotificationManager } from "react-notifications";
import { withLayoutContextProvider } from "services/LayoutContext";
import ApiErrorsHelper from "helpers/ApiErrorsHelper";

import { FormGroup, Button, Label } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import ValidationForm from "sharedComponents/validationForm/ValidationForm";
import Input from "sharedComponents/input/Input";
import ImageUploader from "sharedComponents/imageUploader/ImageUploader";
import YoutubeInput from "sharedComponents/youtubeInput/YoutubeInput";
import Select from "sharedComponents/select/Select";
import Checkbox from "sharedComponents/checkbox/Checkbox";

import TipSheetsApi from "admin/apis/tipSheets.api";

class GeneralTab extends React.PureComponent {
  static props = {
    tipSheet: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    faqs: PropTypes.array.isRequired,
  };

  state = {
    type: "",
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;

    await TipSheetsApi.editGeneral(this.props.tipSheet.id, formData).catch(
      (e) => {
        error = e;
      }
    );

    if (error) {
      ApiErrorsHelper.manage(error);
    } else {
      NotificationManager.success("La fiche conseil a été modifiée", null);
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    const { faqs, categories } = this.props;

    const catOptions = categories.map((c) => {
      return {
        value: c.id,
        label: c.title,
      };
    });

    const faqOptions = faqs.map((c) => ({
      value: c.id,
      label: c.title,
    }));

    return (
      <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
        <FormGroup>
          <Label>Titre</Label>
          <Input name="title" value={this.props.tipSheet.title} required />
        </FormGroup>

        <FormGroup className="required">
          <Label>Catégorie</Label>
          <Select
            id="categoryId"
            name="categoryId"
            placeholder="Catégorie"
            required
            options={catOptions}
            value={this.props.tipSheet.categoryId}
          />
        </FormGroup>

        {this.props.tipSheet.type === "video" && (
          <FormGroup>
            <Label>ID YouTube de la vidéo</Label>
            <YoutubeInput
              required
              name="videoId"
              value={this.props.tipSheet.videoId}
            />
          </FormGroup>
        )}

        {this.props.tipSheet.type === "external" && (
          <FormGroup className="required">
            <Label>Lien</Label>
            <Input
              type="text"
              name="externalUrl"
              value={this.props.tipSheet.externalUrl}
              required={this.state.type === "external"}
            />
          </FormGroup>
        )}

        <div className={this.props.tipSheet.type === "faq" ? "" : "d-none"}>
          <FormGroup className="required">
            <Label htmlFor="faqId">FAQ</Label>
            <Select
              id="faqId"
              name="faqId"
              placeholder="Choisir une FAQ"
              required={this.props.tipSheet.type === "faq"}
              options={faqOptions}
              value={this.props.tipSheet.faqId}
            />
          </FormGroup>
        </div>

        <FormGroup>
          <Label>Petite image</Label>
          <ImageUploader
            name="smallPictureFile"
            actualUrl={this.props.tipSheet.smallPictureUrl}
            helpText="Taille attendue : 417px par 417px"
          />
        </FormGroup>

        <FormGroup>
          <Label>Grande image</Label>
          <ImageUploader
            name="largePictureFile"
            actualUrl={this.props.tipSheet.largePictureUrl}
            helpText="Taille attendue : 1125px par 788px"
          />
        </FormGroup>

        <FormGroup>
          <Checkbox
            name="published"
            id="published"
            checked={this.props.tipSheet.published}
            label="Publié"
          />
        </FormGroup>

        <FormGroup>
          <Checkbox
            name="isHighlighted"
            id="isHighlighted"
            checked={this.props.tipSheet.isHighlighted}
            label="Mis en avant"
          />
        </FormGroup>

        <FormGroup className="text-right">
          <Link
            to="/admin/tipSheets"
            className="btn btn-outline-primary mr-1"
            type="button"
          >
            Retour à la liste
          </Link>
          <Button type="submit" color="primary">
            Valider
          </Button>
        </FormGroup>
      </ValidationForm>
    );
  }
}

export default withLayoutContextProvider(withRouter(GeneralTab));
