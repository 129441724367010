import React from "react";
import PropTypes from "prop-types";

import { withLayoutContextProvider } from "../../../../../services/LayoutContext";
import { NotificationManager } from "react-notifications";
import ApiErrorsHelper from "../../../../../helpers/ApiErrorsHelper";

import { FormGroup, Button, Label } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import ValidationForm from "../../../../../sharedComponents/validationForm/ValidationForm";
import Input from "../../../../../sharedComponents/input/Input";
import ImageUploader from "../../../../../sharedComponents/imageUploader/ImageUploader";
import Checkbox from "../../../../../sharedComponents/checkbox/Checkbox";

import NewsApi from "../../../../apis/news.api";
import YoutubeInput from "sharedComponents/youtubeInput/YoutubeInput";

class GeneralTab extends React.PureComponent {
  static props = {
    news: PropTypes.object.isRequired,
  };

  state = {
    type: "",
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;

    await NewsApi.editGeneral(this.props.news.id, formData).catch((e) => {
      error = e;
    });

    if (error) {
      ApiErrorsHelper.manage(error, this.form);
    } else {
      NotificationManager.success("L'actualité a été modifiée", null);
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
        <FormGroup>
          <Label>Titre</Label>
          <Input name="title" value={this.props.news.title} required />
        </FormGroup>

        {this.props.news.type === "video" && (
          <FormGroup>
            <Label>ID YouTube de la vidéo</Label>
            <YoutubeInput
              required
              name="videoId"
              value={this.props.news.videoId}
            />
          </FormGroup>
        )}

        {this.props.news.type === "external" && (
          <FormGroup className="required">
            <Label>Lien</Label>
            <Input
              type="text"
              name="externalUrl"
              value={this.props.news.externalUrl}
              required={this.state.type === "external"}
            />
          </FormGroup>
        )}

        <FormGroup>
          <Label>Petite image</Label>
          <ImageUploader
            name="smallPictureFile"
            actualUrl={this.props.news.smallPictureUrl}
            helpText="Taille attendue : 417px par 417px"
          />
        </FormGroup>

        {this.props.news.type === "text" && (
          <FormGroup>
            <Label>Grande image</Label>
            <ImageUploader
              name="largePictureFile"
              actualUrl={this.props.news.largePictureUrl}
              helpText="Taille attendue : 1125px par 788px"
            />
          </FormGroup>
        )}

        <FormGroup>
          <Checkbox
            name="published"
            id="published"
            checked={this.props.news.published}
            label="Publiée"
          />
        </FormGroup>

        <FormGroup className="text-right">
          <Link
            to="/centre/news"
            className="btn btn-outline-primary mr-1"
            type="button"
          >
            Retour à la liste
          </Link>
          <Button type="submit" color="primary">
            Valider
          </Button>
        </FormGroup>
      </ValidationForm>
    );
  }
}

export default withLayoutContextProvider(withRouter(GeneralTab));
