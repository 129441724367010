import ApiClient from "../services/ApiClient";

export default class FaqApi {
  static getAll = () => ApiClient.get("faq");

  static add = (data) => ApiClient.post("faq", data);

  static edit = (faqId, data) => ApiClient.patch(`faq/${faqId}`, data);

  static delete = (faqId) => ApiClient.delete(`faq/${faqId}`);

  static get(faqId) {
    return ApiClient.get(`faq/${faqId}`);
  }

  static getItems(faqId) {
    return ApiClient.get(`faq/${faqId}/items`);
  }

  static addItem(faqId, data) {
    return ApiClient.post(`faq/${faqId}/items`, data);
  }

  static editItem(faqId, itemId, data) {
    return ApiClient.patch(`faq/${faqId}/items/${itemId}`, data);
  }

  static moveItem = (faqId, itemId, data) =>
    ApiClient.patch(`faq/${faqId}/items/${itemId}/order`, data);

  static deleteItem(faqId, itemId) {
    return ApiClient.delete(`faq/${faqId}/items/${itemId}`);
  }
}
