import React from "react";
import PropTypes from "prop-types";

import { NotificationManager } from "react-notifications";
import ApiErrorsHelper from "helpers/ApiErrorsHelper";
import { withLayoutContextProvider } from "services/LayoutContext";

import { FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import StructuredContent from "sharedPagesComponents/common/structuredContent/StructuredContent";

import NewsApi from "admin/apis/news.api"; 

class ContentTab extends React.PureComponent {
  static props = {
    news: PropTypes.object.isRequired,
  };

  state = {
    structuredContent: this.props.news.structuredContent,
  };

  onBlockAdded = async (block) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let modifiedContent = await NewsApi.addBlock(
      this.props.news.id,
      block.type,
      block.data
    ).catch((e) => {
      error = e;
      return null;
    });

    if (error) {
      ApiErrorsHelper.manage(error);
    } else {
      this.setState({ structuredContent: modifiedContent });
      NotificationManager.success("L'élément a été ajouté", null);
    }

    this.props.layoutContextProvider.hideLoader();
    return Promise.resolve();
  };

  onBlockMoved = (movement, reorderedBlocks) => {
    this.props.layoutContextProvider.showLoader();

    var previousContent = this.state.structuredContent;
    this.setState(
      {
        structuredContent: { ...previousContent, blocks: reorderedBlocks },
      },
      async () => {
        let movedBlock = previousContent.blocks[movement.from];

        let error = null;
        let newContent = await NewsApi.moveBlock(
          this.props.news.id,
          movedBlock.id,
          { position: movement.to }
        ).catch((e) => {
          error = e;
          return null;
        });

        let nextState = {};
        if (error) {
          ApiErrorsHelper.manage(error);
          nextState.structuredContent = previousContent;
        } else {
          nextState.structuredContent = newContent;
          NotificationManager.success("L'élément a été déplacé", null);
        }

        this.setState(nextState);
        this.props.layoutContextProvider.hideLoader();
        return Promise.resolve();
      }
    );
  };

  onBlockEdited = async (block) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let modifiedContent = await NewsApi.editBlock(
      this.props.news.id,
      block.id,
      block.data
    ).catch((e) => {
      error = e;
      return null;
    });

    if (error) {
      ApiErrorsHelper.manage(error);
    } else {
      this.setState({ structuredContent: modifiedContent });
      NotificationManager.success("L'élément a été modifié", null);
    }

    this.props.layoutContextProvider.hideLoader();
    return Promise.resolve();
  };

  onBlockDeleted = async (block) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let modifiedContent = await NewsApi.deleteBlock(
      this.props.news.id,
      block.id
    ).catch((e) => {
      error = e;
      return null;
    });

    if (error) {
      ApiErrorsHelper.manage(error);
    } else {
      this.setState({ structuredContent: modifiedContent });
      NotificationManager.success("L'élément a été supprimé", null);
    }

    this.props.layoutContextProvider.hideLoader();
    return Promise.resolve();
  };

  render() {
    return !this.props.news ? null : (
      <div>
        <StructuredContent
          editable
          blocks={this.state.structuredContent.blocks}
          onBlockAdded={this.onBlockAdded}
          onBlockMoved={this.onBlockMoved}
          onBlockEdited={this.onBlockEdited}
          onBlockDeleted={this.onBlockDeleted}
        />
        <FormGroup className="text-right">
          <Link
            to="/admin/news"
            className="btn btn-outline-primary"
            type="button"
          >
            Retour à la liste
          </Link>
        </FormGroup>
      </div>
    );
  }
}

export default withLayoutContextProvider(ContentTab);
