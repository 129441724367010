import { PropTypes } from 'prop-types'
import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class PreviewModalButton extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node
  }

  state = {
    modalVisible: false
  }

  toggleModal = () =>
    this.setState(prevState => {
      return { modalVisible: !prevState.modalVisible }
    })

  render () {
    return (
      <>
        <Button
          color='primary'
          onClick={this.toggleModal}
          title='Visualiser'
          outline
          className="mr-2"
        >
          <FontAwesomeIcon icon='eye' /> Visualiser
        </Button>
        <Modal
          isOpen={this.state.modalVisible}
          toggle={this.toggleModal}
        >
          <ModalHeader toggle={this.toggleModal}>Visualisation</ModalHeader>
          <ModalBody>{this.props.children}</ModalBody>
          <ModalFooter>
            <Button color='primary' outline onClick={this.toggleModal} block>
              Fermer
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}
