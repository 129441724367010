import React from "react";
import _ from "lodash";

import { NotificationManager } from "react-notifications";
import ApiErrorsHelper from "helpers/ApiErrorsHelper";

import { FormGroup, Table, Badge } from "reactstrap";
import Block from "sharedComponents/block/Block";
import PageDataLoader from "sharedComponents/pageDataLoader/PageDataLoader";
import DeleteButton from "sharedComponents/buttons/deleteButton/DeleteButton";
import EditButtonLink from "sharedComponents/buttons/editButtonLink/EditButtonLink";
import CreateNewsButton from "./createNewsButton/CreateNewsButton";
import PreviewButton from "./previewButton/PreviewButton";

import NewsApi from "center/apis/news.api";

export default class NewsListPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    news: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Gestion des actualités",
      activeMenuKey: "news",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      { isLoading: true, loadingError: false, news: null },
      async () => {
        let nextState = { isLoading: false };

        const news = await NewsApi.getAll().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (news) {
          nextState.news = _.orderBy(news, "title");
        }

        this.setState(nextState);
      }
    );
  };

  onCreateNews = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let news = await NewsApi.add(formData).catch((e) => {
      error = e;
      return null;
    });
    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    } else {
      this.props.history.push(`news/${news.id}`);
      return Promise.resolve();
    }
  };

  deleteNews = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await NewsApi.delete(id).catch((error) => {
      ApiErrorsHelper.manage(error);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success("Actualité supprimée avec succès", null);
      this.loadData();
    }
  };

  getType = (news) => {
    switch (news.type) {
      case "video":
        return "Vidéo";
      case "text":
        return "Texte";
      case "external":
        return "Lien externe";
      default:
        return "";
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>actualités</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des actualités"
        >
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.news) return null;

    return (
      <div className="ClinicsPage">
        <>
          <FormGroup className="text-right">
            <CreateNewsButton onSubmit={this.onCreateNews} />
          </FormGroup>
          <Table responsive hover striped>
            <thead>
              <tr>
                <th>Titre</th>
                <th>Type</th>
                <th className="width-auto"></th>
                <th className="text-center width-auto">Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.news.map((news) => {
                return (
                  <tr key={news.id}>
                    <td>{news.title}</td>
                    <td>{this.getType(news)}</td>
                    <td className="text-center">
                      <Badge color={news.published ? "success" : "secondary"}>
                        {news.published ? "Publiée" : "Non publiée"}
                      </Badge>
                    </td>
                    <td className="width-auto text-center">
                      <PreviewButton news={news} />{" "}
                      <EditButtonLink to={`/centre/news/${news.id}`} />
                      <DeleteButton
                        title={<span>Confirmation de suppression</span>}
                        data={news.id}
                        onConfirm={this.deleteNews}
                        className="ml-1"
                        tooltip="Supprimer"
                      >
                        Voulez-vous réellement supprimer l'actualité{" "}
                        <i>{news.title}</i> ?
                      </DeleteButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      </div>
    );
  };
}
