import React from "react";

import ApiClient from "./services/ApiClient";
import Authentication from "./services/Authentication";

import { Redirect } from "react-router-dom";
import PrivateLayout from "../layouts/privateLayout/PrivateLayout";

export default class CenterPrivateLayout extends React.PureComponent {
  editorialMenuItems = [
    {
      menuKey: "myCenter",
      label: "Mon centre",
      icon: "hospital",
      path: "/",
    },
    {
      menuKey: "news",
      label: "Actualités",
      icon: "newspaper",
      path: "/news",
    },
    {
      menuKey: "contacts",
      label: "Contacts",
      icon: "address-book",
      path: "/contacts",
    },
  ];

  constructor(props) {
    super(props);
    Authentication.loadFromStorage();
  }

  hasMultipleRightsCategories = () => {
    let nbRights = 0;

    if (Authentication.user.isEditorialAdmin) {
      nbRights++;
    }

    if (Authentication.user.isQoDAdmin) {
      nbRights++;
    }

    if (Authentication.user.isKpisAdmin) {
      nbRights++;
    }

    if (this.hasOneAdminRights()) {
      nbRights++;
    }

    return nbRights > 0;
  };

  hasOneAdminRights = () => {
    return Authentication.user.isSuperAdmin;
  };

  render() {
    if (!Authentication.isLogged()) {
      return <Redirect to={`/centre/login`} />;
    }

    let sideMenuItems = [];

    sideMenuItems = sideMenuItems.concat(this.editorialMenuItems);

    // Admin
    if (this.hasOneAdminRights()) {
      sideMenuItems.push({
        menuKey: "admin",
        label: "Administration",
        subItems: [
          {
            menuKey: "adminUsers",
            label: "Utilisateurs",
            icon: "users",
            path: "/admin/users",
          },
        ],
      });
    }

    return (
      <PrivateLayout
        {...this.props}
        sideMenuTitle="ELSAN Rein"
        sideMenuSubitle="Back Office clinique"
        routesPrefix="centre"
        unauthorizedRoutePath="login"
        authentication={Authentication}
        apiClient={ApiClient}
        sideMenuItems={sideMenuItems}
      />
    );
  }
}
