import ApiClient from "../services/ApiClient";

export default class CenterContactsApi {
  static getAll = () => {
    return ApiClient.get("contacts");
  };

  static delete = (id) => ApiClient.delete(`contacts/${id}`);
  static add = (data) => ApiClient.post("contacts", data, true);
  static get = (id) => ApiClient.get(`contacts/${id}`);
  static update = (id, data) => ApiClient.post(`contacts/${id}`, data, true);
}