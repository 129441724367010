import _ from "lodash";
import { FormGroup, Table, Label } from "reactstrap";
import { NotificationManager } from "react-notifications";
import ApiErrorsHelper from "helpers/ApiErrorsHelper";
import Block from "sharedComponents/block/Block";
import CreateButtonModal from "sharedComponents/buttons/createButtonModal/CreateButtonModal";
import EditButtonLink from "sharedComponents/buttons/editButtonLink/EditButtonLink";
import Input from "sharedComponents/input/Input";
import PageDataLoader from "sharedComponents/pageDataLoader/PageDataLoader";
import React from "react";
import validationErrors from "services/ValidationErrors";

import FaqApi from "admin/apis/faq.api";
import DeleteButton from "sharedComponents/buttons/deleteButton/DeleteButton";

export default class FaqListPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    faq: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Gestion des FAQ",
      activeMenuKey: "faq",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        centers: null,
      },
      async () => {
        let nextState = { isLoading: false };
        nextState.faq = await FaqApi.getAll().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (nextState.faq) {
          nextState.faq = _.orderBy(nextState.faq, "name");
        }

        this.setState(nextState);
      }
    );
  };

  onCreate = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let faq = await FaqApi.add(formData).catch((e) => {
      error = e;
      return null;
    });
    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    } else {
      this.props.history.push(`faq/${faq.id}`);
      return Promise.resolve();
    }
  };

  onDelete = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await FaqApi.delete(id).catch((error) => {
      ApiErrorsHelper.manage(error);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success("FAQ supprimée avec succès", null);
      this.loadData();
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>FAQ</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
        >
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.faq) return null;

    return (
      <div className="CentersPage">
        <FormGroup className="text-right">
          <CreateButtonModal text="Créer une FAQ" onSubmit={this.onCreate}>
            <FormGroup className="required">
              <Label htmlFor="internalCode">Code interne</Label>
              <Input
                className="form-control"
                name="internalCode"
                required
                maxLength={50}
                validations="maxLength:50"
                validationError={validationErrors.maxLength(50)}
              />
            </FormGroup>
            <FormGroup className="required">
              <Label htmlFor="title">Titre</Label>
              <Input
                className="form-control"
                name="title"
                required
                maxLength={255}
                validations="maxLength:255"
                validationError={validationErrors.maxLength(255)}
              />
            </FormGroup>
          </CreateButtonModal>
        </FormGroup>

        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Nom</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.faq.map((f) => {
              return (
                <tr key={f.id}>
                  <td>{f.title}</td>
                  <td className="width-auto text-center">
                    <EditButtonLink to={`/admin/faq/${f.id}`} />
                    <DeleteButton
                      title={<span>Confirmation de suppression</span>}
                      data={f.id}
                      onConfirm={f.canBeDeleted ? this.onDelete : null}
                      className="ml-1"
                      tooltip={
                        f.canBeDeleted
                          ? "Supprimer"
                          : "Impossible de supprimer une FAQ utilisée dans une fiche conseil"
                      }
                      disabled={!f.canBeDeleted}
                    >
                      Voulez-vous réellement supprimer la FAQ <i>{f.title}</i> ?
                    </DeleteButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
