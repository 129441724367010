import { withFormsy } from "formsy-react";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect, { components as ReactSelectComponent } from "react-select";
import "./Select.scss";

import validationErrors from "../../services/ValidationErrors";

class Select extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    clearable: PropTypes.bool,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    searchable: PropTypes.bool,
    multi: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.object
        ])
      })
    ).isRequired,
    optionsTemplate: PropTypes.func
  };

  static defaultProps = {
    clearable: true,
    placeholder: "Sélection",
    required: false,
    searchable: true
  };

  changeValue = option => {
    let value = "";

    if (Array.isArray(option)) {
      let values = option.map(x => {
        return x.value;
      });

      value = values;
    } else {
      value = option ? option.value : null;
    }

    this.props.setValue(value);

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  onBlur = () => {
    this.props.setValue(this.props.value);
  };

  onClear = () => this.changeValue(null);

  getSelectedOption = () => {
    const value = this.props.value;

    if (Array.isArray(value)) {
      let options = this.props.options.filter(option => {
        return value.includes(option.value);
      });

      return options;
    } else {
      let option = null;
      if (value !== null) {
        for (let opt of this.props.options) {
          if (opt.value === value) {
            option = opt;
            break;
          }
        }
      }

      return option;
    }
  };

  render() {
    const errorMessage = this.props.errorMessage;

    let components = {};
    if (this.props.optionsTemplate) {
      components.Option = props => (
        <ReactSelectComponent.Option {...props}>
          <this.props.optionsTemplate {...props.data} />
        </ReactSelectComponent.Option>
      );

      components.SingleValue = ({ children, ...props }) => (
        <ReactSelectComponent.SingleValue {...props}>
          <this.props.optionsTemplate {...props.data} />
        </ReactSelectComponent.SingleValue>
      );
    }

    return (
      <div
        className={
          this.props.isPristine
            ? ""
            : this.props.isValid
            ? "valid"
            : "invalid"
        }
      >
        <ReactSelect
          id={this.props.id}
          classNamePrefix="customSelect"
          className="customSelect"
          name={this.props.name}
          required={this.props.required}
          isClearable={this.props.clearable}
          isSearchable={this.props.searchable}
          isMulti={this.props.multi}
          placeholder={this.props.placeholder}
          components={components}
          valid={!this.props.isPristine && this.props.isValid}
          invalid={!this.props.isPristine && !this.props.isValid}
          onBlur={this.onBlur}
          options={this.props.options}
          defaultValue={null}
          value={this.getSelectedOption()}
          onChange={this.changeValue}
        />

        {!this.props.isPristine && this.props.showRequired ? (
          <div className="invalid-feedback">{validationErrors.required}</div>
        ) : (
          <div className="invalid-feedback">{errorMessage}</div>
        )}
      </div>
    );
  }
}

export default withFormsy(Select);
