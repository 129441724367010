import { PropTypes } from "prop-types";
import React from "react";

import "./ImageBlockRenderer.scss";

export default class ImageBlockRenderer extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    return (
      <img
        className="imageBlockRenderer"
        src={`${this.props.data.url}`}
        alt=""
      />
    );
  }
}
