import React from "react";

import classnames from "classnames";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Block from "sharedComponents/block/Block";
import GeneralTab from "./generalTab/GeneralTab";
import UsersTab from "./usersTab/UsersTab";

export default class EditCenterPage extends React.PureComponent {
  state = {
    activeTab: window.location.hash
      ? window.location.hash.substr(1)
      : "general",
  };

  constructor(props) {
    super(props);
    this.id = this.props.match.params.id;
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Gestion des centres",
      activeMenuKey: "centers",
    });
  }

  goToGeneralTab = () => {
    this.setState({
      activeTab: "general",
    });

    this.props.history.replace("#general");
  };

  goToUsersTab = () => {
    this.setState({
      activeTab: "users",
    });

    this.props.history.replace("#users");
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Modification d'un <strong>centre</strong>
          </span>
        }
      >
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "general",
              })}
              onClick={this.goToGeneralTab}
            >
              Général
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "users",
              })}
              onClick={this.goToUsersTab}
            >
              Utilisateurs
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="general">
            <GeneralTab
              centerId={this.id}
            />
          </TabPane>
          <TabPane tabId="users">
            <UsersTab
              centerId={this.id}
            />
          </TabPane>
        </TabContent>
      </Block>
    );
  }
}
