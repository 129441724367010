import React from "react";

import _ from "lodash";
import { NotificationManager } from "react-notifications";
import ApiErrorsHelper from "../../../../helpers/ApiErrorsHelper";

import { FormGroup, Table } from "reactstrap";

import CreateButtonLink from "../../../../sharedComponents/buttons/createButtonLink/CreateButtonLink";
import EditButtonLink from "../../../../sharedComponents/buttons/editButtonLink/EditButtonLink";
import DeleteButton from "../../../../sharedComponents/buttons/deleteButton/DeleteButton";
import Block from "../../../../sharedComponents/block/Block";
import PageDataLoader from "../../../../sharedComponents/pageDataLoader/PageDataLoader";

import CenterContactsApi from "../../../apis/centerContacts.api";

export default class ContactListPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    contacts: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Gestion des contacts",
      activeMenuKey: "contacts",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      { isLoading: true, loadingError: false, contacts: null },
      async () => {
        let nextState = { isLoading: false };
        nextState.contacts = await CenterContactsApi.getAll().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (nextState.contacts) {
          nextState.contacts = _.orderBy(nextState.contacts, "lastName");
        }

        this.setState(nextState);
      }
    );
  };

  onDelete = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await CenterContactsApi.delete(id).catch((error) => {
      ApiErrorsHelper.manage(error);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success("Le contact a été supprimé.", null);
      this.loadData();
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>contacts</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des contacts"
        >
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.contacts) return null;
    return (
      <div className="ContactListPage">
        <FormGroup className="text-right">
          <CreateButtonLink to="/centre/contacts/add" text="Nouveau contact" />
        </FormGroup>

        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Nom</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.contacts.map((contact) => {
              return (
                <tr key={contact.id}>
                  <td>
                    {contact.lastName} {contact.firstName}
                  </td>
                  <td className="width-auto text-center">
                    <EditButtonLink
                      to={`/centre/contacts/${contact.id}`}
                      text="Modifier un contact"
                    />
                    <DeleteButton
                      data={contact.id}
                      title="Supprimer un contact"
                      className="ml-1"
                      onConfirm={this.onDelete}
                    >
                      Voulez-vous réellement supprimer le contact{" "}
                      <i>
                        {contact.lastName} {contact.firstName}
                      </i>{" "}
                      ?
                    </DeleteButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
