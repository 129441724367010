import { FormGroup, Button, Label } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { withLayoutContextProvider } from "services/LayoutContext";
import ApiErrorsHelper from "helpers/ApiErrorsHelper";
import Input from "sharedComponents/input/Input";
import PropTypes from "prop-types";
import React from "react";
import validationErrors from "services/ValidationErrors";
import ValidationForm from "sharedComponents/validationForm/ValidationForm";

import FaqApi from "admin/apis/faq.api";

class GeneralTab extends React.PureComponent {
  static props = {
    faq: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  state = {
    type: "",
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;

    await FaqApi.edit(this.props.faq.id, formData).catch((e) => {
      error = e;
    });

    if (error) {
      ApiErrorsHelper.manage(error, this.form);
    } else {
      NotificationManager.success("La FAQ a été modifiée", null);
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    const { faq } = this.props;
    return (
      <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
        <FormGroup className="required">
          <Label htmlFor="internalCode">Code interne</Label>
          <Input
            className="form-control"
            name="internalCode"
            required
            maxLength={50}
            validations="maxLength:50"
            validationError={validationErrors.maxLength(50)}
            value={faq.internalCode}
          />
        </FormGroup>
        <FormGroup className="required">
          <Label htmlFor="title">Titre</Label>
          <Input
            className="form-control"
            name="title"
            required
            maxLength={255}
            validations="maxLength:255"
            validationError={validationErrors.maxLength(255)}
            value={faq.title}
          />
        </FormGroup>

        <FormGroup className="text-right">
          <Link
            to="/admin/faq"
            className="btn btn-outline-primary mr-1"
            type="button"
          >
            Retour à la liste
          </Link>
          <Button type="submit" color="primary">
            Valider
          </Button>
        </FormGroup>
      </ValidationForm>
    );
  }
}

export default withLayoutContextProvider(withRouter(GeneralTab));
