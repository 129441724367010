import React from "react";
import PropTypes from "prop-types";

import { NotificationManager } from "react-notifications";
import ApiErrorsHelper from "helpers/ApiErrorsHelper";
import { withLayoutContextProvider } from "services/LayoutContext";

import { Table, FormGroup } from "reactstrap";
import DeleteButton from "sharedComponents/buttons/deleteButton/DeleteButton";
import AddPhotoButton from "./addPhotoButton/AddPhotoButton";

import CenterApi from "center/apis/center.api";

class PhotosTab extends React.PureComponent {
  static propTypes = {
    photos: PropTypes.array.isRequired,
    reload: PropTypes.func.isRequired,
  };

  onAddPhoto = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;

    await CenterApi.addPhoto(formData).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    } else {
      NotificationManager.success("Photo ajoutée avec succès", "");
      this.props.reload();
      return Promise.resolve();
    }
  };

  deletePhoto = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;

    await CenterApi.deletePhoto(id).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      ApiErrorsHelper.manage(error);
    } else {
      NotificationManager.success("Photo supprimée avec succès", "");
      this.props.reload();
    }
  };

  render() {
    return (
      <>
        <FormGroup className="text-right">
          <AddPhotoButton onSubmit={this.onAddPhoto}>
            Nouvelle photo
          </AddPhotoButton>
        </FormGroup>

        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Photo</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.props.photos.map((photo) => {
              return (
                <tr key={photo.id}>
                  <td>
                    <img src={photo.photoUrl} width="200" alt="" />
                  </td>
                  <td className="width-auto text-center">
                    <DeleteButton
                      title={<span>Confirmation de suppression</span>}
                      data={photo.id}
                      onConfirm={this.deletePhoto}
                      className="ml-1"
                      tooltip="Supprimer"
                    >
                      Voulez-vous réellement supprimer la photo ?
                    </DeleteButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  }
}

export default withLayoutContextProvider(PhotosTab);
