import React from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Error404 extends React.PureComponent {
  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div className="error404">
        <div>Oups !</div>
        <h1>404</h1>
        <div>La page que vous recherchez n'existe pas</div>

        {this.props.history.length > 1 && (
          <Button onClick={this.goBack} className="mt-4">
            <FontAwesomeIcon icon="chevron-left" className="mr-2" />
            Revenir en arrière
          </Button>
        )}
      </div>
    );
  }
}
