import React from "react";
import Input from "sharedComponents/input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class YoutubeInput extends React.PureComponent {
  state = {
    videoId: this.props.value ?? "",
  };

  onVideoIdChange = (name, videoId) => this.setState({ videoId: videoId });

  render() {
    const { onChange, type, ...inputProps } = this.props;

    return (
      <div className="d-flex flex-row align-items-start">
        <Input type="text" onChange={this.onVideoIdChange} {...inputProps} />

        <div className="ml-2 mt-1">
          <a
            href={`https://www.youtube.com/watch?v=${this.state.videoId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary btn-sm"
            title="Regarder"
          >
            <FontAwesomeIcon icon="play" />
          </a>
        </div>
      </div>
    );
  }
}
