import React from "react";

import classnames from "classnames";
import { withLayoutContextProvider } from "services/LayoutContext";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import PageDataLoader from "sharedComponents/pageDataLoader/PageDataLoader";
import Block from "sharedComponents/block/Block";
import GeneralTab from "./generalTab/GeneralTab";
import PhotosTab from "./photosTab/PhotosTab";

import CenterApi from "center/apis/center.api";

class MyCenterPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    center: null,
    activeTab: "general",
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Mon centre",
      activeMenuKey: "myCenter",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: null,
        center: null,
      },
      async () => {
        let nextState = { isLoading: false, loadingError: false };

        nextState.center = await CenterApi.get().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        this.setState(nextState);
      }
    );
  };

  openTab = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Gestion de mon <strong>centre</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement du centre"
        >
          {this.state.center && this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    return (
      <>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "general",
              })}
              onClick={() => this.openTab("general")}
            >
              Général
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "photos",
              })}
              onClick={() => this.openTab("photos")}
            >
              Photos
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="general">
            <GeneralTab center={this.state.center} />
          </TabPane>
          <TabPane tabId="photos">
            <PhotosTab
              photos={this.state.center.photos}
              reload={this.loadData}
            />
          </TabPane>
        </TabContent>
      </>
    );
  };
}

export default withLayoutContextProvider(MyCenterPage);
