import React from "react";
import _ from "lodash";

import { NotificationManager } from "react-notifications";

import { FormGroup, Table } from "reactstrap";
import Block from "sharedComponents/block/Block";
import PageDataLoader from "sharedComponents/pageDataLoader/PageDataLoader";
import CreateCategoryButton from "./createCategoryButton/CreateCategoryButton";
import EditCategoryButton from "./editCategoryButton/EditCategoryButton";

import TipSheetCategories from "admin/apis/tipSheetCategories.api";

export default class TipSheetCategoriesPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    categories: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Gestion des catégories de fiches conseil",
      activeMenuKey: "tipSheetCategories",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      { isLoading: true, loadingError: false, news: null },
      async () => {
        let nextState = { isLoading: false };

        nextState.categories = await TipSheetCategories.getAll().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (nextState.categories) {
          nextState.categories = _.orderBy(nextState.categories, ["title"]);
        }

        this.setState(nextState);
      }
    );
  };

  onCreate = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await TipSheetCategories.add(formData).catch((e) => {
      error = e;
      return null;
    });
    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    } else {
      NotificationManager.success("Catégorie créée avec succès", null);
      this.loadData();
      return Promise.resolve();
    }
  };

  onEdit = async (category, formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;

    await TipSheetCategories.edit(category.id, formData).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    } else {
      NotificationManager.success("Catégorie modifiée avec succès", null);
      this.loadData();
      return Promise.resolve();
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>catégories de fiches conseil</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des catégories de fiches conseil"
        >
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.categories) return null;

    return (
      <>
        <FormGroup className="text-right">
          <CreateCategoryButton onSubmit={this.onCreate} />
        </FormGroup>

        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Titre</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.categories.map((category) => {
              return (
                <tr key={category.id}>
                  <td>{category.title}</td>
                  <td className="width-auto text-center">
                    <EditCategoryButton category={category} onSubmit={this.onEdit} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  };
}
