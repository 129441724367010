import { PropTypes } from "prop-types";
import React from "react";
import HtmlEditor from "../../../../../sharedComponents/htmlEditor/HtmlEditor";
import { Label } from "reactstrap";

export default class TextBlockEditor extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object
  };

  render() {
    return (
      <div className="textBlockEditor">
        <Label>Texte:</Label>
        <HtmlEditor
          name="text"
          value={this.props.data ? this.props.data.text : ""}
          required
        />
      </div>
    );
  }
}
