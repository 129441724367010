import React from "react";
import PropTypes from "prop-types";

import _ from "lodash";
import { NotificationManager } from "react-notifications";
import { withLayoutContextProvider } from "services/LayoutContext";
import ApiErrorsHelper from "helpers/ApiErrorsHelper";


import { FormGroup, Table } from "reactstrap";
import CreateButtonModal from "sharedComponents/buttons/createButtonModal/CreateButtonModal";
import DeleteButton from "sharedComponents/buttons/deleteButton/DeleteButton";
import EditButtonModal from "sharedComponents/buttons/editButtonModal/EditButtonModal";
import PageDataLoader from "sharedComponents/pageDataLoader/PageDataLoader";
import UserForm from "./userForm/UserForm";

import CentersApi from "admin/apis/centers.api";

class UsersTab extends React.PureComponent {
  static propTypes = {
    centerId: PropTypes.string.isRequired,
    layoutContextProvider: PropTypes.object,
  };

  state = {
    isLoading: true,
    loadingError: false,
    users: null,
  };

  componentDidMount() {
    this.loadUsers();
  }

  loadUsers = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        users: null,
      },
      async () => {
        let nextState = { isLoading: false };
        nextState.users = await CentersApi.getUsers(this.props.centerId).catch(
          (error) => {
            nextState.loadingError = true;
            return null;
          }
        );

        if (nextState.users) {
          nextState.users = _.sortBy(nextState.users, "name");
        }

        this.setState(nextState);
      }
    );
  };

  saveAddModal = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await CentersApi.addUser(this.props.centerId, formData).catch(
      (response) => {
        error = response;
      }
    );

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success("L'utilisateur a été créé", null);
      this.loadUsers();
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  };

  saveEditModal = async (userId, formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await CentersApi.editUser(this.id, userId, formData).catch((response) => {
      error = response;
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success("Modification effectuée", null);
      this.loadUsers();
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  };

  deleteUser = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await CentersApi.deleteUser(this.props.centerId, id).catch((error) => {
      ApiErrorsHelper.manage(error);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success("Utilisateur supprimé avec succès", null);
      this.loadUsers();
    }
  };

  render() {
    return (
      <PageDataLoader
        isLoading={this.state.isLoading}
        loadingError={this.state.loadingError}
        loadingText="Chargement des protocoles"
      >
        {this.renderContent()}
      </PageDataLoader>
    );
  }

  renderContent = () => {
    if (!this.state.users) return null;

    const users = this.state.users;
    return (
      <div>
        <FormGroup className="text-right">
          <CreateButtonModal
            text="Nouvel utilisateur"
            onSubmit={this.saveAddModal}
          >
            <UserForm passwordRequired />
          </CreateButtonModal>
        </FormGroup>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Email</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              return (
                <tr key={user.id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td className="width-auto text-center">
                    <EditButtonModal
                      title="Modifier un utilisateur"
                      data={user.id}
                      onSubmit={this.saveEditModal}
                      className="mr-1"
                    >
                      <UserForm user={user} />
                    </EditButtonModal>
                    <DeleteButton
                      title={
                        <span>
                          Supprimer <i>{user.name}</i>
                        </span>
                      }
                      data={user.id}
                      onConfirm={this.deleteUser}
                      className="ml-1"
                    >
                      Voulez-vous réellement supprimer <i>{user.name}</i> ?
                    </DeleteButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}

export default withLayoutContextProvider(UsersTab);
