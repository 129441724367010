import { withFormsy } from "formsy-react";
import { CKEditor } from "ckeditor4-react";
import PropTypes from "prop-types";
import React from "react";

import validationErrors from "../../services/ValidationErrors";

class HtmlEditor extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    allowLinks: true,
  };

  onEditorChange = (event) => {
    const value = event.editor.getData();
    this.props.setValue(value);

    if (this.props.onChange) {
      this.props.onChange(this.props.name, event.currentTarget.value);
    }
  };

  render() {
    const {
      errorMessage,
      value,
      allowLinks,
      isPristine,
      isValid,
      showRequired,
    } = this.props;

    let toolbarConfig = [["Bold", "Italic", "Underline"], ["BulletedList"]];

    if (allowLinks) {
      toolbarConfig.push(["Link", "Unlink"]);
    }

    return (
      <div className={isPristine ? "" : isValid ? "valid" : "invalid"}>
        <CKEditor
          initData={value}
          config={{
            removePlugins: "elementspath",
            removeButtons: "",
            toolbar: toolbarConfig,
          }}
          onChange={this.onEditorChange}
        />

        {!isPristine && showRequired ? (
          <div className="invalid-feedback">{validationErrors.required}</div>
        ) : (
          <div className="invalid-feedback">{errorMessage}</div>
        )}
      </div>
    );
  }
}

export default withFormsy(HtmlEditor);