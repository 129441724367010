import { BrowserRouter, Switch } from "react-router-dom";
import React from "react";

import AdminPrivateLayout from "../admin/AdminPrivateLayout";
import AdminRoutes from "./AdminRoutes";
import CenterPrivateLayout from "../center/CenterPrivateLayout";
import CommonRoutes from "./CommonRoutes";
import ErrorsLayout from "../layouts/errorsLayout/ErrorsLayout";
import PublicLayout from "../layouts/publicLayout/PublicLayout";

import CenterRoutes from "./CenterRoutes";
import ScrollToTop from "../sharedComponents/scrollToTop/ScrollToTop";

export default class Router extends React.PureComponent {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          {AdminRoutes.private.map((route) => (
            <AdminPrivateLayout
              {...{ ...route, path: `/admin${route.path}` }}
            />
          ))}

          {AdminRoutes.public.map((route) => (
            <PublicLayout {...{ ...route, path: `/admin${route.path}` }} />
          ))}

          {CenterRoutes.private.map((route) => (
            <CenterPrivateLayout
              {...{ ...route, path: `/centre${route.path}` }}
            />
          ))}

          {CenterRoutes.public.map((route) => (
            <PublicLayout {...{ ...route, path: `/centre${route.path}` }} />
          ))}

          {CommonRoutes.map((route) => (
            <ErrorsLayout {...route} />
          ))}
        </Switch>
      </BrowserRouter>
    );
  }
}
