import Authentication from "./Authentication";
import HttpClient from "../../services/HttpClient";

export default class ApiClient {
  static refreshingPromise = null;

  static prefix = "admin";

  static authExpired = null;

  static addAuthExpiredListener = callback => {
    ApiClient.authExpired = () => callback();
    return ApiClient.authExpired;
  };

  static removeAuthExpiredListener = callback => {
    if (ApiClient.authExpired === callback) {
      ApiClient.authExpired = null;
    }
  };

  static request = async (
    method,
    route,
    body = null,
    multipart = false,
    allowTokenRefresh = true
  ) => {
    let error = null;

    if (ApiClient.refreshingPromise) {
      await ApiClient.refreshingPromise.catch(e => {
        error = e;
      });
    }

    if (error) {
      return Promise.reject(error);
    } else {
      return HttpClient.request(
        method,
        `${ApiClient.prefix}/${route}`,
        Authentication.token,
        body,
        multipart
      ).catch(async e => {
        console.log(e.status);
        if (e.status === 401 && allowTokenRefresh) {
          ApiClient.refreshToken().catch(() => {});
          return ApiClient.request(method, route, body, multipart, false);
        } else {
          return Promise.reject(e);
        }
      });
    }
  };

  static refreshToken = () => {
    ApiClient.refreshingPromise = new Promise(async (resolve, reject) => {
      let newAuth = await HttpClient.request(
        "GET",
        `${ApiClient.prefix}/login/refresh`,
        Authentication.token,
        null,
        null
      ).catch(e => {
        if (ApiClient.authExpired) {
          ApiClient.authExpired();
        }

        reject();
      });

      if (newAuth) {
        Authentication.login(newAuth.token);
        resolve();
      }

      ApiClient.refreshingPromise = null;
    });

    return ApiClient.refreshingPromise;
  };

  static get = async route => ApiClient.request("GET", route);
  static post = async (route, body = null, multipart = false) =>
    ApiClient.request("POST", route, body, multipart);
  static put = async (route, body = null, multipart = false) =>
    ApiClient.request("PUT", route, body, multipart);
  static patch = async (route, body = null, multipart = false) =>
    ApiClient.request("PATCH", route, body, multipart);
  static delete = async route => ApiClient.request("DELETE", route);
}
