import React from "react";
import { PropTypes } from "prop-types";

import ApiErrorsHelper from "../../../../../helpers/ApiErrorsHelper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import StructuredContent from "../../../../../sharedPagesComponents/common/structuredContent/StructuredContent";
import YouTube from "react-youtube";

import NewsApi from "../../../../apis/news.api";

export default class PreviewButton extends React.PureComponent {
  static propTypes = {
    news: PropTypes.object.isRequired,
  };

  state = {
    isOpen: false,
    structuredContent: null,
  };

  preview = async () => {
    if (this.props.news.type === "external") {
      window.open(this.props.news.externalUrl);
    } else if (this.props.news.type === "video") {
      this.setState({ isOpen: true });
    } else {
      let nextState = { isOpen: true };

      if (this.state.structuredContent === null) {
        let news = null;

        if (this.props.news.dialysisCenterId === null) {
          news = await NewsApi.getGroup(this.props.news.id, true).catch((e) => {
            ApiErrorsHelper.manage(e);
            return null;
          });
        } else {
          news = await NewsApi.get(this.props.news.id, true).catch((e) => {
            ApiErrorsHelper.manage(e);
            return null;
          });
        }

        if (news === null) {
          nextState.isOpen = false;
        } else {
          nextState.structuredContent = news.structuredContent;
        }
      }

      this.setState(nextState);
    }
  };

  onToggle = () => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    if (!this.props.news) {
      return null;
    }

    const withPopup =
      this.props.news.type === "text" || this.props.news.type === "video";

    return (
      <span className="deleteButtonContainer">
        <Button
          color="primary"
          size="sm"
          onClick={this.preview}
          title="Prévisualiser"
        >
          <FontAwesomeIcon icon="search" />
        </Button>
        {withPopup && (
          <Modal isOpen={this.state.isOpen} toggle={this.onToggle}>
            <ModalHeader toggle={this.props.onToggle}>
              {this.props.news.title}
            </ModalHeader>
            <ModalBody>
              {this.props.news.type === "text" && (
                <StructuredContent
                  blocks={this.state.structuredContent?.blocks}
                />
              )}
              {this.props.news.type === "video" && (
                <YouTube
                  videoId={this.props.news.videoId}
                  opts={{ width: "100%" }}
                />
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" outline onClick={this.onToggle} block>
                Fermer
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </span>
    );
  }
}
