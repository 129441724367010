import FaqApi from "admin/apis/faq.api";
import React from "react";
import Block from "sharedComponents/block/Block";
import PageDataLoader from "sharedComponents/pageDataLoader/PageDataLoader";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import GeneralTab from "./generalTab/GeneralTab";
import ItemsTab from "./itemsTab/ItemsTab";
import { NotificationManager } from "react-notifications";

export default class EditFaqPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    faq: null,
    activeTab: "general",
  };

  componentDidMount() {
    this.id = this.props.match.params.id;

    this.props.layoutContextProvider.change({
      title: "Gestion des FAQ",
      activeMenuKey: "faq",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        faq: null,
      },
      async () => {
        let nextState = { isLoading: false };

        nextState.faq = await FaqApi.get(this.id, true).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        this.setState(nextState);
      }
    );
  };

  goToGeneralTab = () => {
    this.setState({ activeTab: "general" });
    this.props.history.replace("#general");
  };

  goToItemsTab = () => {
    this.setState({ activeTab: "items" });
    this.props.history.replace("#items");
  };

  onCreateItem = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await FaqApi.addItem(this.id, formData).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      NotificationManager.error(error.message, "Une erreur est survenue");
    } else {
      NotificationManager.success("La question a été ajoutée", null);
      this.loadData();
    }

    return error ? Promise.reject(error) : Promise.resolve();
  };

  onChangeItemsOrder = (movement, reorderedItems) => {
    this.props.layoutContextProvider.showLoader();
    const previousFaq = this.state.faq;
    this.setState(
      { faq: { ...this.state.faq, items: reorderedItems } },
      async () => {
        const movedItem = previousFaq.items[movement.from];

        let error = null;
        let newItems = FaqApi.moveItem(this.id, movedItem.id, {
          position: movement.to,
        }).catch((e) => {
          error = e;
          return null;
        });

        let nextState = {};
        if (error) {
          NotificationManager.error(error.message, "Une erreur est survenue");
          nextState.faq = previousFaq;
        } else {
          nextState.structuredContent = { ...previousFaq, items: newItems };
          NotificationManager.success("La question a été déplacée", null);
        }

        this.setState(nextState);
        this.props.layoutContextProvider.hideLoader();
      }
    );
  };

  onEditItem = async (itemId, formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await FaqApi.editItem(this.id, itemId, formData).catch((e) => {
      error = e;
    });

    if (error) {
      NotificationManager.error(error.message, "Une erreur est survenue");
    } else {
      NotificationManager.success("La question a été modifiée", null);
      this.loadData();
    }

    this.props.layoutContextProvider.hideLoader();
  };

  onDeleteItem = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await FaqApi.deleteItem(this.id, id).catch((e) => {
      error = e;
    });

    if (error) {
      NotificationManager.error(error.message, "Une erreur est survenue");
    } else {
      NotificationManager.success("La question a été supprimée", null);
      this.loadData();
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Modifier une <strong>FAQ</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement de la FAQ"
        >
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    const { faq } = this.state;
    if (!faq) {
      return null;
    }

    return (
      <>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "general",
              })}
              onClick={this.goToGeneralTab}
            >
              Général
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "items",
              })}
              onClick={this.goToItemsTab}
            >
              Questions
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="general">
            <GeneralTab faq={faq} />
          </TabPane>
          <TabPane tabId="items">
            <ItemsTab
              faq={faq}
              onChangeOrder={this.onChangeItemsOrder}
              onCreate={this.onCreateItem}
              onEdit={this.onEditItem}
              onDelete={this.onDeleteItem}
            />
          </TabPane>
        </TabContent>
      </>
    );
  };
}
