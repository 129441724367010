import ApiClient from "../services/ApiClient";

export default class CenterApi {
  static get = () => ApiClient.get("center");

  static update = (data) => ApiClient.post("center", data, true);

  static addPhoto = (data) => ApiClient.post("center/photos", data, true);

  static deletePhoto = (photoId) =>
    ApiClient.delete(`center/photos/${photoId}`);
}
