import { PropTypes } from "prop-types";
import React from "react";

import CreateButtonModal from "../../../../sharedComponents/buttons/createButtonModal/CreateButtonModal";
import ImageBlockEditor from "../blockRenderer/imageBlock/ImageBlockEditor";
import QuoteBlockEditor from "../blockRenderer/quoteBlock/QuoteBlockEditor";
import Select from "../../../../sharedComponents/select/Select";
import SubtitleBlockEditor from "../blockRenderer/subtitleBlock/SubtitleBlockEditor";
import TextBlockEditor from "../blockRenderer/textBlock/TextBlockEditor";
import TitleBlockEditor from "../blockRenderer/titleBlock/TitleBlockEditor";

const blockTypes = [
  {
    value: "text",
    label: "Texte",
  },
  {
    value: "title",
    label: "Titre",
  },
  {
    value: "subtitle",
    label: "Sous-titre",
  },
  {
    value: "quote",
    label: "Citation",
  },
  {
    value: "image",
    label: "Image",
  },
];

export default class AddBlockButton extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    nextOrderValue: PropTypes.number,
  };

  static defaultProps = {
    nextOrderValue: 1,
  };

  state = {
    selectedType: null,
  };

  onSelectedTypeChanged = (value) => this.setState({ selectedType: value });

  onSubmit = async (formData) => {
    const { type, ...data } = formData;

    return this.props.onSubmit({
      type: type,
      data: data,
      order: this.props.nextOrderValue,
    });
  };

  render() {
    let form = null;

    switch (this.state.selectedType) {
      case "title":
        form = <TitleBlockEditor text="" />;
        break;
      case "subtitle":
        form = <SubtitleBlockEditor text="" />;
        break;
      case "quote":
        form = <QuoteBlockEditor text="" />;
        break;
      case "text":
        form = <TextBlockEditor text="" />;
        break;
      case "image":
        form = <ImageBlockEditor url="" />;
        break;
      default:
        form = null;
        break;
    }

    return (
      <CreateButtonModal text="Ajouter un élément" onSubmit={this.onSubmit}>
        <Select
          id="type"
          name="type"
          placeholder="Choisir un bloc"
          required
          value={this.state.selectedType}
          options={blockTypes}
          onChange={this.onSelectedTypeChanged}
        />
        <div className="mt-2">{form}</div>
      </CreateButtonModal>
    );
  }
}
