import React from "react";

import _ from "lodash";

import { Table, FormGroup, Badge } from "reactstrap";
import Block from "sharedComponents/block/Block";
import CreateButtonLink from "sharedComponents/buttons/createButtonLink/CreateButtonLink";
import EditButtonLink from "sharedComponents/buttons/editButtonLink/EditButtonLink";
import PageDataLoader from "sharedComponents/pageDataLoader/PageDataLoader";

import CentersApi from "admin/apis/centers.api";

export default class CentersPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    centers: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Gestion des centres",
      activeMenuKey: "centers",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      { isLoading: true, loadingError: false, centers: null },
      async () => {
        let nextState = { isLoading: false };
        nextState.centers = await CentersApi.getAll().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (nextState.centers) {
          nextState.centers = _.orderBy(nextState.centers, "name");
        }

        this.setState(nextState);
      }
    );
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>centres</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des centres"
        >
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.centers) return null;

    return (
      <div className="CentersPage">
        <FormGroup className="text-right">
          <CreateButtonLink to="/admin/centers/add" text="Nouveau centre" />
        </FormGroup>

        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Nom</th>
              <th className="width-auto"></th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.centers.map((center) => {
              return (
                <tr key={center.id}>
                  <td>{center.name}</td>
                  <td className="text-center">
                    <Badge color={center.active ? "success" : "danger"}>
                      {center.active ? "Actif" : "Non actif"}
                    </Badge>
                  </td>
                  <td className="width-auto text-center">
                    <EditButtonLink to={`/admin/centers/${center.id}`} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
