import React from "react";
import { PropTypes } from "prop-types";

import { Label, FormGroup } from "reactstrap";
import Input from "sharedComponents/input/Input";
import EditButtonModal from "sharedComponents/buttons/editButtonModal/EditButtonModal";

export default class EditCategoryButton extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    category: PropTypes.object.isRequired,
  };

  state = {
    modalVisible: false,
  };

  toggleModal = () =>
    this.setState((prevState) => {
      return { modalVisible: !prevState.modalVisible };
    });

  onSubmit = (category, formData) => {
    return this.props.onSubmit(category, formData);
  };

  render() {
    return (
      <EditButtonModal
        data={this.props.category}
        title="Modifier une catégorie"
        tooltip="Modifier une catégorie"
        onSubmit={this.onSubmit}
      >
        <FormGroup className="required">
          <Label>Titre</Label>
          <Input
            type="text"
            name="title"
            required
            value={this.props.category.title}
          />
        </FormGroup>
      </EditButtonModal>
    );
  }
}
