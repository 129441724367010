import StringHelper from "../../helpers/StringHelper";
import jwt_decode from "jwt-decode";

export default class Authentication {
  static storageKey = "adelReinCenterAdmin";
  static token = null;
  static user = null;

  static loadFromStorage = () => {
    let json = null;
    const item = localStorage.getItem(Authentication.storageKey);

    if (item) {
      try {
        json = JSON.parse(item);

        // check structure & clean if altered
        if (
          StringHelper.isNullOrEmpty(json.token) ||
          StringHelper.isNullOrEmpty(json.token) ||
          !json.user
        ) {
          localStorage.setItem(Authentication.storageKey, null);
          return null;
        }

        Authentication.token = json.token;
        Authentication.user = json.user;
      } catch (e) {}
    }

    return json;
  };

  static isLogged = () => {
    if (
      !Authentication.user ||
      !StringHelper.isNullOrEmpty(Authentication.token)
    ) {
      let json = Authentication.loadFromStorage();
      return json && !StringHelper.isNullOrEmpty(json.token);
    }

    return true;
  };

  static login = (token) => {
    const tokenData = jwt_decode(token);

    let data = {
      token: token,
      user: {
        id: tokenData.sub,
        name: tokenData.name,
        email: tokenData.email,
        isSuperAdmin: tokenData.isSuperAdmin,
      },
    };

    Authentication.token = token;
    Authentication.user = data.user;

    localStorage.setItem(Authentication.storageKey, JSON.stringify(data));
  };

  static logout = () => {
    Authentication.token = null;
    Authentication.user = null;

    localStorage.setItem(Authentication.storageKey, null);
  };
}
