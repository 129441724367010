import React from "react";

import _ from "lodash";
import { NotificationManager } from "react-notifications";
import ApiErrorsHelper from "helpers/ApiErrorsHelper";
import Authentication from "admin/services/Authentication";

import { Table, FormGroup } from "reactstrap";
import Block from "sharedComponents/block/Block";
import DeleteButton from "sharedComponents/buttons/deleteButton/DeleteButton";
import PageDataLoader from "sharedComponents/pageDataLoader/PageDataLoader";
import CreateButtonModal from "sharedComponents/buttons/createButtonModal/CreateButtonModal";
import EditButtonModal from "sharedComponents/buttons/editButtonModal/EditButtonModal";
import AdminForm from "./adminForm/AdminForm";

import AdminsApi from "admin/apis/admins.api";

export default class AdminsPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    admins: null
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Gestion des administrateurs",
      activeMenuKey: "admins"
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      { isLoading: true, loadingError: false, admins: null },
      async () => {
        let nextState = { isLoading: false };
        let response = await AdminsApi.getAll().catch(e => {
          nextState.loadingError = true;
          return null;
        });

        if (response) {
          nextState.admins = _.orderBy(response, "name");
        }

        this.setState(nextState);
      }
    );
  };

  saveAddModal = async formData => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await AdminsApi.create(formData).catch(e => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success("L'administrateur a été créé", null);
    this.loadData();
    return Promise.resolve(true);
  };

  saveEditModal = async (adminId, formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await AdminsApi.update(adminId, formData).catch(e => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success("Administrateur modifié avec succès", null);
    this.loadData();
    return Promise.resolve(true);
  };

  deleteAdmin = async id => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await AdminsApi.delete(id).catch(error => {
      ApiErrorsHelper.manage(error);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success("Administrateur supprimé avec succès", null);
      this.loadData();
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>administrateurs</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des cliniques"
        >
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.admins) return null;

    return (
      <div>
        <FormGroup className="text-right">
          <CreateButtonModal
            text="Nouvel administrateur"
            onSubmit={this.saveAddModal}
          >
            <AdminForm></AdminForm>
          </CreateButtonModal>
        </FormGroup>

        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Nom</th>
              <th className="width-auto">Super Admin</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.admins.map(admin => {
              return (
                <tr key={admin.id}>
                  <td>{admin.name}</td>
                  <td className="text-center">
                    {admin.isSuperAdmin ? "Oui" : "Non"}
                  </td>
                  <td className="width-auto text-center">
                    <EditButtonModal
                      title="Modifier un administrateur"
                      data={admin.id}
                      onSubmit={this.saveEditModal}
                      className="mr-1"
                    >
                      <AdminForm user={admin} />
                    </EditButtonModal>

                    {Authentication.user.id !== admin.id && (
                      <DeleteButton
                        title={
                          <span>
                            Supprimer <i>{admin.name}</i>
                          </span>
                        }
                        data={admin.id}
                        onConfirm={this.deleteAdmin}
                        className="ml-1"
                      >
                        Voulez-vous réellement supprimer <i>{admin.name}</i> ?
                      </DeleteButton>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
